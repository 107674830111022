import React from 'react';
import { Grid } from '@material-ui/core';
import { TextBoxShrink } from '../../../../Core/FormInput';
import { get } from 'lodash';

export const BreakdownDefectRepair = React.memo((props) => {
    const { state, setState } = props; //useContext(JobSheetContext);
    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
                <TextBoxShrink
                    multiline
                    minRows={3}
                    onChange={setField}
                    value={getField('service24BreakdownLocation')}
                    label="Breakdown Location"
                    name="service24BreakdownLocation"
                    inputProps={{ maxLength: 256 }} 
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextBoxShrink
                    multiline
                    minRows={3}
                    onChange={setField}
                    value={getField('service24DefectDescription')}
                    label="Defect"
                    name="service24DefectDescription"
                    inputProps={{ maxLength: 2048 }} 
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
                <TextBoxShrink
                    multiline
                    minRows={3}
                    onChange={setField}
                    value={getField('service24RepairDetails')}
                    label="Repairs"
                    name="service24RepairDetails"
                    inputProps={{ maxLength: 200000 }} 
                />
            </Grid>
        </Grid>
    );
});
