import { CircularProgress, Grid, LinearProgress, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSaleUsedInvoice, getSalesEVtarget } from '../../../../Core/Service/groupDashboard-services';
import formatters from '../../../../Core/Grid/inputFormatter';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const setPercentage = (op, budget) => {
    let vehicleDiff = null;
    vehicleDiff = (op / budget) * 100;
    return vehicleDiff <= 100 ? vehicleDiff : 100;
};

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,

        backgroundColor: (props) => {
            let bgColor = props.value < 50 ? 'red' : props.value >= 50 && props.value < 75 ? '#FFBF00' : 'green';

            return bgColor;
        }
    }
}))(LinearProgress);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#5c5c5c',
        color: 'white',
        padding: 9,
        border: 'none',
        fontWeight: 600
    },
    body: {
        backgroundColor: '#f5f5f5',
        fontSize: 14,
        border: 'none',
        padding: 9,
        borderBottom: '1px solid rgba(0,0,0,0.009)'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0,0,0,0.009)'
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledTableRowGrey = withStyles((theme) => ({
    root: {
        backgroundColor: 'blue'
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        overflow: 'auto',
        minWidth: '1400px',
        border: '1px solid rgba(0,0,0,0.002)'
    },
    surChargetable: {
        overflow: 'auto',
        minWidth: '100%',
        border: '1px solid rgba(0,0,0,0.002)'
    }
});

const VehicleSalesEvTarget = (props) => {
    const location = useLocation();
    const [width, height] = useWindowSize();
    const queryParameters = new URLSearchParams(location.search);
    const lable = queryParameters.get('label');
    const type = queryParameters.get('type');
    const history = useHistory();

    const handleRedirect = (params) => {
        let start = '';
        let end = '';
        switch (params) {
            case 1:
                start = moment().month(0).startOf('month').format('YYYY-MM-DD');
                end = moment().month(2).endOf('month').format('YYYY-MM-DD');
                break;
            case 2:
                start = moment().month(3).startOf('month').format('YYYY-MM-DD');
                end = moment().month(5).endOf('month').format('YYYY-MM-DD');
                break;
            case 3:
                start = moment().month(6).startOf('month').format('YYYY-MM-DD');
                end = moment().month(8).endOf('month').format('YYYY-MM-DD');
                break;
            case 4:
                start = moment().month(9).startOf('month').format('YYYY-MM-DD');
                end = moment().month(11).endOf('month').format('YYYY-MM-DD');
                break;
        }
        let searchQuery = `?start=${start}&end=${end}&isElectric=${true}`;
        history.push({
            pathname: `/sales/NewVehicleProfitReport`,
            search: searchQuery
        });
    };

    const [state, setState] = useState({
        ScreenLabel: lable,
        typeId: type,
        opList: {},
        bugetList: {}
    });

    useEffect(async () => {
        let res = await getSalesEVtarget();
        console.log(res);
        if (res.success) {
            setState((st) => ({
                ...st,
                opList: res?.data?.actual[0] || [],
                TotalOP: res?.data?.actual[0]?.q1 + res?.data?.actual[0]?.q2 + res?.data?.actual[0]?.q3 + res?.data?.actual[0]?.q4 || null,
                bugetList: res?.data?.budget[0] || [],
                Totalbudget: res?.data?.budget[0]?.q1 + res?.data?.budget[0]?.q2 + res?.data?.budget[0]?.q3 + res?.data?.budget[0]?.q4 || null
            }));
        }
    }, [type]);

    const classes = useStyles();
    return (
        <div style={{ padding: 10 }}>
            {/* {state.fllteredList.length > 0 ? ( */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        style={{
                            textAlign: 'left',
                            marginLeft: 10,
                            marginBottom: width >= 600 ? 20 : 0,
                            fontWeight: 600,
                            fontSize: width >= 600 ? 24 : 18
                        }}
                    >
                        {state.ScreenLabel}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.surChargetable} stickyHeader aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">
                                        <b></b>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">OP</StyledTableCell>
                                    <StyledTableCell align="center" width={'50%'}>
                                        &nbsp;
                                    </StyledTableCell>
                                    <StyledTableCell align="center">EV TARGET</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow onClick={() => handleRedirect(1)}>
                                    <StyledTableCell>
                                        <b>Q1</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.opList?.q1}</StyledTableCell>
                                    <StyledTableCell>
                                        <abbr title={`${Math.round(setPercentage(state?.opList?.q1, state?.bugetList?.q1))}%`}>
                                            <BorderLinearProgress variant="determinate" value={setPercentage(state?.opList?.q1, state?.bugetList?.q1)} />
                                        </abbr>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.bugetList?.q1}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow onClick={() => handleRedirect(2)}>
                                    <StyledTableCell>
                                        <b>Q2</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.opList?.q2}</StyledTableCell>
                                    <StyledTableCell>
                                        {' '}
                                        <abbr title={`${Math.round(setPercentage(state?.opList?.q2, state?.bugetList?.q2))}%`}>
                                            <BorderLinearProgress variant="determinate" value={setPercentage(state?.opList?.q2, state?.bugetList?.q2)} />
                                        </abbr>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.bugetList?.q2}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow onClick={() => handleRedirect(3)}>
                                    <StyledTableCell>
                                        <b>Q3</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.opList?.q3}</StyledTableCell>
                                    <StyledTableCell>
                                        {' '}
                                        <abbr title={`${Math.round(setPercentage(state?.opList?.q3, state?.bugetList?.q3))}%`}>
                                            <BorderLinearProgress variant="determinate" value={setPercentage(state?.opList?.q3, state?.bugetList?.q3)} />
                                        </abbr>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.bugetList?.q3}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow onClick={() => handleRedirect(4)}>
                                    <StyledTableCell>
                                        <b>Q4</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.opList?.q4}</StyledTableCell>
                                    <StyledTableCell>
                                        {' '}
                                        <abbr title={`${Math.round(setPercentage(state?.opList?.q4, state?.bugetList?.q4))}%`}>
                                            <BorderLinearProgress variant="determinate" value={setPercentage(state?.opList?.q4, state?.bugetList?.q4)} />
                                        </abbr>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{state?.bugetList?.q4}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRowGrey>
                                    <StyledTableCell align="left">
                                        <div>
                                            <b>Total</b>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <div>
                                            <b>{state.TotalOP}</b>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <abbr title={`${Math.round(setPercentage(state?.TotalOP, state?.Totalbudget))}%`}>
                                            <BorderLinearProgress variant="determinate" value={setPercentage(state.TotalOP, state?.Totalbudget)} />
                                        </abbr>
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                        <div>
                                            <b>{state.Totalbudget}</b>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRowGrey>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {/* ) : (
                <Grid
                    container
                    style={{
                        // padding: '10px 0px',
                        height: 'calc(100vh - 105px)',
                        overflow: 'auto'
                    }}
                    justifyContent="center"
                    className="salesBudgetScreen"
                    alignItems="center"
                >
                    <CircularProgress color="secondary" />
                </Grid>
            )} */}
        </div>
    );
};

export default VehicleSalesEvTarget;
