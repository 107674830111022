import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions, DefaultButton } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { addWebForm } from './../../../Core/Service/webForm-service';
import { MenuItem } from '@material-ui/core';
import { getWorkflows } from '../../../Core/Service/workflow-service';

const AddWebFormModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        name: '',
        buttonLabel: '',
        url: '',
        description: '',
        errors: {},
        fields: {},
        nextWorkFlow: null,
        workFlowList: [],
        navigateToDashboard: false
    });
    const WebFormId = props.WebFormId;
    const WebFormDetails = props.WebFormDetails;

    useEffect(async () => {
        const WebformData = WebFormDetails;
        if (WebFormDetails) {
            setState((st) => ({
                ...st,
                name: WebformData.name,
                url: WebformData.webFormRelativeUrl,
                buttonLabel: WebformData.webFormButtonLabel,
                description: WebformData.nonFunctionalDescription,
                nextWorkFlow: WebformData.webformEditableWorkflowID,
                navigateToDashboard: WebformData.navigateToDashboardOnSubmit
            }));
        }

        let result = await getWorkflows();
        if (result.success) {
            setState((st) => ({ ...st, workFlowList: result.data }));
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            let res = await addWebForm(WebFormId, state.name, state.url, state.buttonLabel, state.description, state.nextWorkFlow, state.navigateToDashboard);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(true);
            }
            showToast(res.message);
        }
    };
    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.name) {
            formIsValid = false;
            errors['name'] = 'Name cannot be empty';
        }
        if (!state.url) {
            formIsValid = false;
            errors['url'] = 'Url cannot be empty';
        }
        if (!state.buttonLabel) {
            formIsValid = false;
            errors['buttonLabel'] = 'Button label cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{WebFormId ? <DialogTitles>Update Web Form</DialogTitles> : <DialogTitles>Add Web Form</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Name
                        </FormLabel>
                        <TextBox autoFocus name="name" onChange={fieldChange} value={state.name} />
                        <span className="mandatoryfields">{state.errors['name']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            URL
                        </FormLabel>
                        <TextBox name="url" onChange={fieldChange} value={state.url} />
                        <span className="mandatoryfields">{state.errors['url']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Button Label
                        </FormLabel>
                        <TextBox name="buttonLabel" onChange={fieldChange} value={state.buttonLabel} />
                        <span className="mandatoryfields">{state.errors['buttonLabel']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Editable in Workflow</FormLabel>
                        <TextBox select name="nextWorkFlow" value={state.nextWorkFlow} onChange={fieldChange} variant="outlined">
                            {state.workFlowList
                                .filter((m) => m.id)
                                .map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.workflowName}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Description</FormLabel>
                        <TextBox name="description" onChange={fieldChange} value={state.description} multiline rows={2} />
                    </Grid>
                </Grid>
            </DialogContents>
            <Grid container xs={12} sm={12}>
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={10} sm={10}>
                    <Grid container spacing={0}>
                        <Grid item xs={2} sm={2}>
                            <FormLabel component="legend" className="btn_add">
                                Navigate to dashboard?
                            </FormLabel>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={state.navigateToDashboard ? 'btnActive' : 'btninActive'}
                                value={true}
                                name="navigateToDashboard"
                                onClick={() => handleClick('navigateToDashboard', true)}
                            >
                                Yes
                            </DefaultButton>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={state.navigateToDashboard ? 'btninActive' : 'btnActiveNo'}
                                value={false}
                                name="navigateToDashboard"
                                onClick={() => handleClick('navigateToDashboard', false)}
                            >
                                No
                            </DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddWebFormModal;
