import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IdleTimeOutModal } from './SessionModal';
import { AppStorage } from '../Service/storage-service';

const IdleTimeOutHandler = (props) => {
    let timer = undefined;
    const events = ['click', 'load', 'keydown'];
    let appStorageTimeOut = AppStorage.getPortalSetting().portalSettingTimeOut;
    let userMinutes = appStorageTimeOut ? appStorageTimeOut != null && appStorageTimeOut - 1 : 59;
    let TimeUserMilliSecond = userMinutes * 60000;
    const [showModal, setShowModal] = useState(false);
    const [isLogout, setLogout] = useState(false);

    const eventHandler = (eventType) => {
        if (!isLogout) {
            if (timer) {
                props.onActive();
                startTimer();
            }
        }
    };

    useEffect(() => {
        addEvents();
        return () => {
            removeEvents();
            clearTimeout(timer);
        };
    }, []);

    const startTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(
            () => {
                if (isLogout) {
                    clearTimeout(timer);
                } else {
                    props.onIdle();
                    setShowModal(true);
                }
            },
            TimeUserMilliSecond ? TimeUserMilliSecond : 300000
        );
    };

    const addEvents = () => {
        events.forEach((eventName) => {
            window.addEventListener(eventName, eventHandler);
        });
        startTimer();
    };

    const removeEvents = () => {
        events.forEach((eventName) => {
            window.removeEventListener(eventName, eventHandler);
        });
    };

    const handleContinueSession = () => {
        setShowModal(false);
        setLogout(false);
    };
    const handleLogout = () => {
        removeEvents();
        clearTimeout(timer);
        setLogout(true);
        props.onLogout();
        setShowModal(false);
        props.Logout();
    };

    return (
        <div>
            <IdleTimeOutModal showModal={showModal} handleContinue={handleContinueSession} handleLogout={handleLogout} />
        </div>
    );
};

export default IdleTimeOutHandler;
