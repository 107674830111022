import {
    get,
    post
} from './http-calls';

export const saveDocumentStamps = async (jobId, docId, docName, stamps) => {
    let res = await post(`Jobdocuments/SetDocumentStampsJSON`, {
        headerRecordId: jobId,
        ocrDocumentId: docId,
        documentName: docName,
        documentStampsJSON: stamps
    }, {
        useAuthToken: true
    });
    return res;
}

//this is new stamping logic, we send one canvas not all multiple stamps
export const saveDocumentStampsV2 = async (jobId, docId, docName, canvasofPages) => {
    let res = await post(`Jobdocuments/v2/SetDocumentStampsJSON`, {
        headerRecordId: jobId,
        ocrDocumentId: docId,
        documentName: docName,
        canvasofPages
    }, {
        useAuthToken: true
    });
    return res;
}

export const getDocumentStamps = async (jobId, docId, docName) => {
    let res = await get(`Jobdocuments/GetDocumentStampsJSON/${jobId}?ocrDocumentId=${docId}&documentName=${docName}`, {
        useAuthToken: true
    });
    return res;
}
