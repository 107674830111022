import { Grid, Typography } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import formatters from '../../../../Core/Grid/inputFormatter';
import './miniStyle.scss';

const setPercentage = (profit, budget) => {
    if (budget == null || budget == 0) {
        return 100;
    } else {
        let value = (+profit / +budget) * 100;

        return Math.round(value);
    }
};

export const VehicleCard = (props) => {
    const { value, profit, budget, label } = props;

    return (
        <Grid container style={{ textAlign: 'justify' }} justifyContent="space-around" alignItems="center" className="detailDashmainCard">
            <Grid item xs={12} style={{ padding: 10 }}>
                <Typography className="salesCardTextPrimary alignLeft ml-10">{label}</Typography>
            </Grid>
            <Grid item>
                <div className="vehicleRing">
                    <CircularProgressbar
                        value={setPercentage(profit, budget)}
                        text={`${setPercentage(profit, budget) || 0}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            trailColor: 'lightgrey',
                            // strokeLinecap: '',
                            textSize: '27px'
                        })}
                        strokeWidth={8}
                    />
                </div>
            </Grid>
            {/* <Grid item={2}></Grid> */}

            {props.isElectric ? (
                <Grid item xs={8} sm={7}>
                    <div
                        style={{
                            marginBottom: 5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary alignLeft w50">ACTUAL</div>
                        <div className="salesCurrencyFont alignLeft w50">{formatters.ThousandSeparatorWithoutZero(profit)}</div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="salesCardTextPrimary alignLeft w50">BUDGET</div>
                        <div className="salesCurrencyFont alignLeft w50">{formatters.ThousandSeparatorWithoutZero(budget)}</div>
                    </div>
                </Grid>
            ) : (
                <Grid item xs={8} sm={7}>
                    <div
                        style={{
                            marginBottom: 5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary alignLeft w50">ACTUAL</div>
                        <div className="salesCurrencyFont alignLeft w50">
                            {' '}
                            {props.unit}&nbsp;/&nbsp;{formatters.CurrencyThousandSeparatorWithoutZero(profit)}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="salesCardTextPrimary alignLeft w50">BUDGET</div>
                        <div className="salesCurrencyFont alignLeft w50">
                            {' '}
                            {props.budgetunit}&nbsp;/&nbsp;{formatters.CurrencyThousandSeparatorWithoutZero(budget)}
                        </div>
                    </div>
                </Grid>
            )}
            <Grid item xs={12}>
                <div
                    style={{
                        marginBottom: 20
                    }}
                ></div>
            </Grid>
            {/* <Grid item></Grid> */}
        </Grid>
    );
};
