import { FormLabel, Grid } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import Barcode from 'react-barcode';
import { getJobUserAssigned, uploadJobUserAssigned } from '../../../Core/Service/workflowJobs-service';
import { SecondaryButton, PrimaryButton, DefaultButton, SelectBox, SingelSelect } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';
import DataGridComp from '../../../Core/Grid';
import formatters from '../../../Core/Grid/inputFormatter';

const column_new = [
    { field: 'userAssigned', headerName: 'Assigned To', flex: 1 },
    { field: 'dateAssigned', headerName: 'Assigned Date', flex: 1, renderCell: (params) => formatters.DateTimeFormatter(params.row.dateAssigned) },
    {
        field: 'assignedBy',
        headerName: 'Assigned By',
        flex: 1
    },
    { field: 'unassignedDate', headerName: 'Unassigned Date', flex: 1, renderCell: (params) => formatters.DateTimeFormatter(params.row.unassignedDate) }
];

export default function JobAssignedUser(props) {
    const [state, setState] = useState({ userList: [], assignedUserID: '', errors: {} });
    const { hideModal, showToast } = useContext(AppContext);

    const pullJobAssignedUser = async () => {
        let res = await getJobUserAssigned(props.jobId);

        console.log('saveAndRefresh', res);
        setState((st) => ({
            ...st,
            userList: res.data.userList,
            history: res.data.history?.map((p, i) => ({ ...p, id: i })),
            assignedUserID: res.data.details[0].assignedUserID,
            createdBy: res.data.details[0].createdBy,
            dateAssigned: res.data.details[0].dateAssigned,
            regNo: res.data.details[0].regNo,
            wipNumber: res.data.details[0].wipNumber
        }));
    };
    useEffect(() => {
        pullJobAssignedUser();
    }, []);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        if (handleValidation()) {
            let formData = {
                headerRecordId: props.jobId,
                assignedUserID: state.assignedUserID.id
            };
            let res = await uploadJobUserAssigned(formData);
            if (res.success) {
                showToast('User assigned successfully');
                props.onClose();
            } else {
                console.error(res.message);
            }
        }
    };
    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.assignedUserID) {
            formIsValid = false;
            errors['assignedUserID'] = 'Please select user';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    return (
        <div className="App">
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <FormLabel component="legend">Reg No</FormLabel>
                            <br />
                            <FormLabel component="legend">
                                <b>{state.regNo}</b>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormLabel component="legend">WIP No</FormLabel>
                            <br />
                            <FormLabel component="legend">
                                <b>{state.wipNumber}</b>
                            </FormLabel>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <FormLabel component="legend">Assign To</FormLabel>
                    <SelectBox
                        style={{ marginTop: '8px' }}
                        onChange={fieldChange}
                        value={state.assignedUserID}
                        name="assignedUserID"
                        List={state.userList || []}
                    />
                    <span className="mandatoryfields">{state.errors['assignedUserID']}</span>
                </Grid> */}
                <Grid item xs={12} sm={4}>
                    <FormLabel component="legend" style={{ marginTop: -1 }}>
                        Assign To
                    </FormLabel>
                    <SingelSelect onChange={singleSelectChange('assignedUserID')} options={state.userList || []} value={state.assignedUserID} />
                    <span className="mandatoryfields">{state.errors['assignedUserID']}</span>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormLabel component="legend">Assigned Date</FormLabel>
                    <br />
                    <FormLabel component="legend">
                        <b>{state.dateAssigned ? formatters.DateTimeFormatter(state.dateAssigned) : 'N/A'}</b>
                    </FormLabel>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormLabel component="legend">Assigned By</FormLabel>
                    <br />
                    <FormLabel component="legend">
                        <b>{state.createdBy ? state.createdBy : 'N/A'}</b>
                    </FormLabel>
                </Grid>

                <Grid item xs={12}>
                    <DataGridComp rows={state.history} columns={column_new} offset={544} getRowId={(row) => row.id} height="calc(100vh - 435px)" />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
