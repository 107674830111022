import { DataGrid } from '@material-ui/data-grid';
import { find, forEach } from 'lodash';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { PrimaryButton } from '../../../Core/FormInput';
import { assignedTechAndList, assignedTechnicianToJob } from '../../../Core/Service/workflowData-service';
import { Checkbox } from '@material-ui/core';
import './assign-technician.scss';
import { AppStorage } from '../../../Core/Service/storage-service';

const AssignTech = (props) => {
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const { hideTopNavAndMenu, showToast } = useContext(AppContext);
    const getClass = (params) => {
        if (params.row.isAssigned) {
            return 'is-assigned';
        } else if (params.row.selectedNext) {
            return 'will-be-assigned';
        }
    };
    const defaultColumns = [
        { field: 'name', headerName: 'Name', width: 325 },
        { field: 'jobAssigned', headerName: 'Jobs Assigned ', width: 220 },
        {
            field: 'isAssigned',
            headerName: 'Assign',
            width: 150
        }
    ].map((c) => ({ ...c, cellClassName: getClass }));

    let isAssignTechnicianAllowed = AppStorage.getCanAssignTechnicianAllowed();

    const [state, setState] = useState({
        jobId: props.match.params.jobId,
        technicians: [],
        isEnable: false
    });

    const onCheckboxClick = (id) => (e) => {
        const { checked } = e.target;
        if (isAssignTechnicianAllowed) {
            setState((st) => {
                let newSt = { ...st };
                const rw = find(newSt.technicians, (t) => t.id === id);
                if (rw) {
                    rw.selectedNext = checked; //!rw.selectedNext;
                    rw.isAssigned = checked; // !rw.isAssigned;
                    rw.mode = checked ? 'A' : 'D';
                }
                newSt.technicians = [...newSt.technicians];
                newSt.isEnable = true;
                return newSt;
            });
        }
    };

    const columns = () => {
        let allCols = [...defaultColumns];
        let col = allCols.find((c) => c.field === 'isAssigned');
        col.renderCell = (params) => (
            <>
                <Checkbox onClick={onCheckboxClick(params.row.id)} defaultChecked={params.value} disabled={!isAssignTechnicianAllowed} />
                {/* <input type="checkbox"  defaultChecked={params.value} onChange={onCheckboxClick(params.row.id)} /> */}
            </>
        );
        return allCols;
    };

    const getAssignedTechAndOthers = async () => {
        const res = await assignedTechAndList(state.jobId);
        if (res.success) {
            setState((st) => ({ ...st, technicians: res.data }));
        } else {
            showToast(res.message);
        }
    };

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
        getAssignedTechAndOthers();
    }, []);

    const saveTechnicianAndMove = async () => {
        const techToSave = state.technicians.filter((c) => c.mode);
        const res = await assignedTechnicianToJob(state.jobId, techToSave);
        if (res.success) {
            showToast(res.message);
            if (res.success && fromStep) {
                setTimeout(() => {
                    window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                }, 1000);
            }
            props.history.push(`/`);
        }
        if (!res.success) {
            console.error(res);
        }
    };

    return (
        <>
            <div className="assign-tech-grid-container">
                <DataGrid
                    rows={state.technicians}
                    headerHeight={30}
                    rowHeight={30}
                    hideFooter={true}
                    columns={columns()}
                    pageSize={100}
                    disableColumnMenu={true}
                    showColumnRightBorder={true}
                />
                <PrimaryButton fullWidth className="mt-20" onClick={saveTechnicianAndMove} disabled={!state.isEnable}>
                    Assign Technician
                </PrimaryButton>
            </div>
        </>
    );
};

export default AssignTech;
