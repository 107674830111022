import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import formatters from '../../../../Core/Grid/inputFormatter';
import { getReportingInternalHighLevelDashboard } from '../../../../Core/Service/ReportScreens-service';
import './miniStyle.scss';
import { useHistory } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { VehicleCard } from './ministyleComp';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';
const MiniViewReportingDashboard = (props) => {
    const [state, setState] = useState({ data: {}, parts: [], isLoader: true, sales: {} });
    const [WindowWidths, WindowHeights] = useWindowSize();
    const history = useHistory();
    const handleRedirect = () => {
        let link = '/RepotingTab';
        history.push({
            pathname: link
        });
    };

    useEffect(async () => {
        let res = await getReportingInternalHighLevelDashboard();
        setState((st) => ({
            ...st,
            data: res.data.data[0],
            sales: res.data.sales[0],
            parts: res.data.parts || [],
            isLoader: false
        }));
    }, []);

    const redirectTOVehicleDetails = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_newRegistration';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehicleOrderIntek = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_orderIntek';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehicleUsedInvoice = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_usedInvoice';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehiclEVTarget = (label, type) => {
        let searchQuery = `?&label=${label}`;
        let link = '/sales_EvTarget';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };

    return (
        <Grid
            container
            style={{
                height: 'calc(100vh - 105px)',
                overflow: 'auto'
            }}
            justifyContent="center"
            className="salesBudgetScreen"
            alignItems="center"
        >
            {state.isLoader ? (
                <CircularProgress color="secondary" />
            ) : (
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 700, marginBottom: 10 }}>
                                After Sales (MTD)
                            </Typography>
                        </Grid>

                        <Grid container spacing={1} justifyContent={WindowWidths >= 1280 ? 'space-between' : 'flext-start'}>
                            <Grid
                                item
                                xs={12}
                                sm={5}
                                md={3}
                                lg={2}
                                xl={2}
                                // className="DetailDashboard"
                                style={{
                                    cursor: 'pointer',
                                    margin: 10,
                                    padding: 10,
                                    background: 'white',
                                    borderRadius: 10,
                                    boxShadow: '3px 1px 9px -5px rgba(0, 0, 0, 0.3)'
                                }}
                                onClick={() => handleRedirect()}
                            >
                                <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className="grpDetailKpiBox">Sold</Typography>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ height: '90px', width: '90px', textAlign: 'center' }}>
                                            <CircularProgressbar
                                                value={state.data.soldHoursProgress}
                                                text={`${state.data.soldHoursProgress || 0}%`}
                                                styles={buildStyles({
                                                    textColor:
                                                        state.data.soldHoursProgress < 50
                                                            ? '#D92641'
                                                            : state.data.soldHoursProgress >= 50 && state.data.soldHoursProgress < state.data.soldHoursProgress
                                                            ? '#ff9f00'
                                                            : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                            ? '#569101'
                                                            : '#D92641',
                                                    pathColor:
                                                        state.data.soldHoursProgress < 50
                                                            ? '#D92641'
                                                            : state.data.soldHoursProgress >= 50 && state.data.soldHoursProgress < state.data.soldHoursProgress
                                                            ? '#ff9f00'
                                                            : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                            ? '#569101'
                                                            : '#D92641',
                                                    trailColor: '#d6d6d6',
                                                    textSize: '27px'
                                                })}
                                            />
                                        </div>
                                        <div>
                                            <Typography className="mainText">
                                                <span style={{ color: state.data?.soldHoursColour ? state.data?.soldHoursColour : 'black' }}>
                                                    {state.data.soldHours ? formatters.ThousandSeparatorWithoutZero(state.data?.soldHours) : 'N/A'}
                                                </span>
                                            </Typography>
                                            <span className="lableFont">Hours</span>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ height: '90px', width: '90px', textAlign: 'justify' }}>
                                            <CircularProgressbar
                                                value={state.data.soldAmountProgress}
                                                text={`${state.data.soldAmountProgress || 0}%`}
                                                styles={buildStyles({
                                                    textColor:
                                                        state.data.soldAmountProgress < 50
                                                            ? '#D92641'
                                                            : state.data.soldAmountProgress >= 50 &&
                                                              state.data.soldAmountProgress < state.data.soldAmountProgress
                                                            ? '#ff9f00'
                                                            : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                            ? '#569101'
                                                            : '#D92641',
                                                    pathColor:
                                                        state.data.soldAmountProgress < 50
                                                            ? '#D92641'
                                                            : state.data.soldAmountProgress >= 50 &&
                                                              state.data.soldAmountProgress < state.data.soldAmountProgress
                                                            ? '#ff9f00'
                                                            : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                            ? '#569101'
                                                            : '#D92641',
                                                    trailColor: '#d6d6d6',
                                                    textSize: '27px'
                                                })}
                                            />
                                        </div>
                                        <div>
                                            <Typography className="mainText">
                                                <span style={{ color: state.data?.soldAmountColour ? state.data?.soldAmountColour : 'black' }}>
                                                    {state.data.soldAmount ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.soldAmount) : 'N/A'}
                                                </span>
                                            </Typography>
                                            <span className="lableFont">Value</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                lg={2}
                                xl={2}
                                // className="DetailDashboard"
                                style={{
                                    cursor: 'pointer',
                                    margin: 10,
                                    padding: 10,
                                    background: 'white',
                                    borderRadius: 10,
                                    boxShadow: '3px 1px 9px -5px rgba(0, 0, 0, 0.3)'
                                }}
                                onClick={() => handleRedirect()}
                            >
                                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className="grpDetailKpiBox">Labour</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ marginTop: 10 }}>
                                            <div className="mainText">
                                                <span style={{ color: 'black' }}>
                                                    {state.data.warrantyHours ? formatters.ThousandSeparatorWithoutZero(state.data?.warrantyHours) : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: 'black' }}>
                                                    {state.data.warrantyAmount
                                                        ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.warrantyAmount)
                                                        : 'N/A'}
                                                </span>
                                            </div>

                                            <span className="secondaryHeading">
                                                Warranty <span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ marginTop: 5 }}>
                                            <div className="mainText">
                                                <span style={{ color: 'black' }}>
                                                    {state.data.rmHours ? formatters.ThousandSeparatorWithoutZero(state.data?.rmHours) : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: 'black' }}>
                                                    {state.data.rmAmount ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.rmAmount) : 'N/A'}
                                                </span>
                                            </div>
                                            <span className="secondaryHeading">
                                                {' '}
                                                R&M<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={5}
                                lg={3}
                                xl={3}
                                // className="DetailDashboard"
                                style={{
                                    cursor: 'pointer',
                                    margin: 10,
                                    padding: 10,
                                    background: 'white',
                                    borderRadius: 10,
                                    boxShadow: '3px 1px 9px -5px rgba(0, 0, 0, 0.3)'
                                }}
                                onClick={() => handleRedirect()}
                            >
                                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className="grpDetailKpiBox">Parts</Typography>
                                    </Grid>
                                    {state.parts == null || state.parts.length <= 0 ? (
                                        <Grid container justifyContent="space-around" alignItems="center">
                                            No Records Found
                                        </Grid>
                                    ) : (
                                        state.parts.map((item) => {
                                            return (
                                                <Grid item xs={12}>
                                                    <div>
                                                        <div className="mainText">{formatters.CurrencyThousandSeparatorWithoutZero(item.partsAmount)}</div>
                                                        <span className="secondaryHeading">{item.partsDepartment}</span>
                                                    </div>
                                                </Grid>
                                            );
                                        })
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={5}
                                md={3}
                                lg={2}
                                xl={2}
                                // className="DetailDashboard"
                                style={{
                                    cursor: 'pointer',
                                    margin: 10,
                                    padding: 10,
                                    background: 'white',
                                    borderRadius: 10,
                                    boxShadow: '3px 1px 9px -5px rgba(0, 0, 0, 0.3)'
                                }}
                                onClick={() => handleRedirect()}
                            >
                                <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className="grpDetailKpiBox">WIP</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div>
                                            <div className="mainText">
                                                <span style={{ color: state.data?.wipHoursColour ? state.data?.wipHoursColour : 'black' }}>
                                                    {state.data.wipHours ? formatters.ThousandSeparatorWithoutZero(state.data?.wipHours) : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: state.data?.wipAmountColour ? state.data?.wipAmountColour : 'black' }}>
                                                    {state.data.wipAmount ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.wipAmount) : 'N/A'}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className="secondaryHeading">
                                            Surcharges<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Quantity/Value)</span>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div>
                                            <div className="mainText">
                                                {' '}
                                                <span style={{ color: 'black' }}>
                                                    {state.data.surchargeQuantity
                                                        ? formatters.ThousandSeparatorWithoutZero(state.data?.surchargeQuantity)
                                                        : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: 'black' }}>
                                                    {state.data.surchargeAmount
                                                        ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)
                                                        : 'N/A'}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                lg={2}
                                xl={2}
                                // className="DetailDashboard"
                                style={{
                                    cursor: 'pointer',
                                    margin: 10,
                                    padding: 10,
                                    background: 'white',
                                    borderRadius: 10,
                                    boxShadow: '3px 1px 9px -5px rgba(0, 0, 0, 0.3)'
                                }}
                                onClick={() => handleRedirect()}
                            >
                                <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className="grpDetailKpiBox">Customer throughput</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div>
                                            <div className="mainText">
                                                <span style={{ color: 'black' }}>
                                                    {state.data.uniqueCustomers ? formatters.ThousandSeparatorWithoutZero(state.data?.uniqueCustomers) : 'N/A'}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className="grpDetailKpiBox">No. of Technicians</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div>
                                            <div className="mainText">
                                                <span style={{ color: 'black' }}>
                                                    {state.data.technicianBudget
                                                        ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianBudget)
                                                        : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: state.data?.technicianBudget > state.data?.technicianWorking ? 'red' : 'green' }}>
                                                    {state.data.technicianWorking
                                                        ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianWorking)
                                                        : 'N/A'}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 700, marginBottom: 10 }}>
                                Vehicle Sales (MTD)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} onClick={() => redirectTOVehicleDetails('VAN REGISTRATIONS / PROFIT', 1)}>
                                    <VehicleCard
                                        label={'VAN REGISTRATIONS / PROFIT'}
                                        profit={state.sales.vanRegistrationsProfit}
                                        budget={state.sales.vanRegistrationsBudgetProfit}
                                        unit={state.sales.vanRegistrationsUnits}
                                        budgetunit={state.sales.vanRegistrationsBudgetUnits}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} onClick={() => redirectTOVehicleUsedInvoice('VAN USED INVOICE', 1)}>
                                    <VehicleCard
                                        label="VAN USED INVOICE"
                                        profit={state.sales.usedInvocingProfit}
                                        budget={state.sales.usedInvocingBudgetProfit}
                                        unit={state.sales.usedInvocingUnits}
                                        budgetunit={state.sales.usedInvocingBudgetUnits}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                    <Grid container justifyContent="center" alignItems="center" className="detailDashmainCard" style={{ padding: 25 }}>
                                        <Grid container item onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                            <Grid item xs={12}>
                                                <Typography className="grpDetailKpiBox ">VAN ORDER INTAKE</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="mainText ">
                                                    {state?.sales?.vanOrdersUnits}
                                                    {/* /
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state?.sale?.vanOrdersProfit)} */}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ padding: 10 }}></Grid>
                                        </Grid>
                                        <Grid container item onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                            <Grid item xs={12}>
                                                <Typography className="grpDetailKpiBox ">TRUCK ORDER INTAKE</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="mainText ">
                                                    {state?.sales?.truckOrdersUnits}
                                                    {/* /
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.sale?.truckOrdersProfit)} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} onClick={() => redirectTOVehicleDetails('TRUCK REGISTRATIONS / PROFIT', 2)}>
                                    <VehicleCard
                                        label="TRUCK REGISTRATIONS / PROFIT"
                                        profit={state.sales.truckRegistrationsProfit}
                                        budget={state.sales.truckRegistrationsBudgetProfit}
                                        unit={state.sales.truckRegistrationsUnits}
                                        budgetunit={state.sales.usedInvocingBudgetUnits}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} onClick={() => redirectTOVehicleUsedInvoice('TRUCK USED INVOICE', 2)}>
                                    <VehicleCard
                                        label="TRUCK USED INVOICE"
                                        profit={state.sales.truckUsedInvocingProfit}
                                        budget={state.sales.truckUsedInvocingBudgetProfit}
                                        unit={state.sales.truckUsedInvocingUnits}
                                        budgetunit={state.sales.truckUsedInvocingBudgetUnits}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} onClick={() => redirectTOVehiclEVTarget('EV TARGET')}>
                                    <VehicleCard isElectric={true} label="EV TARGET" profit={state.sales.evUnits} budget={state.sales.evBudgetUnits} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default MiniViewReportingDashboard;
