import { get, post } from './http-calls';

export const getAllBranch = async () => {
    let res = await get(`/Branches`, { useAuthToken: true });
    return res;
};

export const getBranchById = async (id) => {
    let res = await get(`/Branches/${id}`, { useAuthToken: true });
    return res;
};

export const postBranchData = async (data) => {
    let res = await post(`Branches/SetStampImage`, data, { useAuthToken: true });
    return res;
};

export const getBranchAndFranchise = async (id) => {
    let res = await get(`/WorkflowGridDropdown`, { useAuthToken: true });
    return res;
};

export const getBudgetDropDown = async (id) => {
    let res = await get(`Budgets/ScreenDropDown`, { useAuthToken: true });
    return res;
};

export const getBudgetExpenses = async (year, departmentID, branchCode) => {
    let res = await get(`Budgets/GetExpenses?Year=${year}&ID=${departmentID}&BranchID=${branchCode}`, { useAuthToken: true });
    return res;
};

export const getBudgetOtherIncomes = async (year, departmentID, branchCode) => {
    let res = await get(`Budgets/GetOtherIncomes?Year=${year}&ID=${departmentID}&BranchID=${branchCode}`, { useAuthToken: true });
    return res;
};
export const getSalesTargets = async (year, departmentID, branchCode) => {
    let res = await get(`Budgets/GetSalesTargets?Year=${year}&ID=${departmentID}&BranchID=${branchCode}`, { useAuthToken: true });
    return res;
};

export const setSalesTargets = async (data) => {
    let res = await post(`Budgets/SetSalesTargets`, data, { useAuthToken: true });
    return res;
};
export const postBudgetExpenceData = async (data) => {
    let res = await post(`Budgets`, data, { useAuthToken: true });
    return res;
};
export const postSetOtherIncomes = async (data) => {
    let res = await post(`Budgets/SetOtherIncomes`, data, { useAuthToken: true });
    return res;
};

export const AddExpenseCategory = async (data) => {
    let res = await post(`Budgets/AddExpenseCategory`, data, { useAuthToken: true });
    return res;
};

export const AddOtherIncomeCategory = async (data) => {
    let res = await post(`Budgets/AddOtherIncomeCategory`, data, { useAuthToken: true });
    return res;
};

export const getBranchCodes = async (id) => {
    let res = await get(`Branches/BranchCodes/${id}`, { useAuthToken: true });
    return res;
};

export const setBranchCodes = async (data) => {
    let res = await post(`Branches/BranchCodes`, data, { useAuthToken: true });
    return res;
};