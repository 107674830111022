import { get } from './http-calls';
import { memoize } from 'lodash';

export const getWarrantyDashBoard = memoize(async () => {
    let res = await get(`services/WarrantyDashBoard`, { useAuthToken: true });
    return res;
});

export const getSoldHoursDashBoard = memoize(async () => {
    let res = await get(`services/SoldHoursDashBoard`, { useAuthToken: true });
    return res;
});

export const getWIPDashBoard = memoize(async () => {
    let res = await get(`services/WIPDashBoard`, { useAuthToken: true });
    return res;
});

export const getGetUserFilterRecords = memoize(async () => {
    let res = await get(`services/GetUserFilterRecords`, { useAuthToken: true });
    return res;
});
