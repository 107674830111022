export const initState = {};

export let reducer = function (state, action) {
    switch (action.type) {
        case 'HandleInput':
            let { name, value } = action.payload;
            return { ...state, [name]: value };

        default:
            return { state };
    }
};
