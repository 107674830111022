import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    TextBox,
    DialogContents,
    DialogTitles,
    Dialogs,
    SecondaryButton,
    DialogsActions,
    DefaultButton,
    AppButtonGroup,
    YesNoButton,
    SelectBox
} from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { Checkbox, IconButton, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { addWorkflow, updateWorkflow, getWorkflowSteps, getWorkflows } from '../../../Core/Service/workflow-service';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import '../User Management/UserManagement.css';
import { getProcesses, getWorkFlowList } from '../../../Core/Service/process-service';
const AddWorkflowModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);

    const [state, setState] = useState({
        name: '',
        IsDeleted: true,
        nextWorkFlow: null,
        IsEntryPoint: false,
        orderIndex: null,
        IsForTechnician: false,
        displayJobPriorityButton: false,
        displayCustomerRatingButton: false,
        workFlowList: [],
        webForm: [],
        triggerList: [
            { triggerVal: 'After', triggerid: true },
            { triggerVal: 'Before', triggerid: false }
        ],

        webFormList: [
            { webFormVal: 'Alway Active', webFormid: true },
            { webFormVal: 'Outstanding', webFormid: false }
        ],
        timedIntervalTypeAfter: null,
        intervalList: [
            { intervalVal: 'Minutes', intervalid: 'M' },
            { intervalVal: 'Hour', intervalid: 'H' },
            { intervalVal: 'Day', intervalid: 'D' }
        ],
        timedInterval: null,
        timesIntervalDuration: null,
        timedWorkflowId: null,
        workflowProcessID: props.workflowProcessID,
        displayWebFormType: '1',
        webFormID: null,
        filterWorkflowList: []
    });

    const [workflowType, setWorkflowType] = useState({
        IsEndPoint: false,
        IsEntryPoint: false,
        IsTechnicianWorkflow: false,
        Standard: true
    });

    const workflowId = props.workflowId;

    const WorkflowDetails = props.WorkflowDetails;

    useEffect(async () => {
        if (workflowId) {
            if (true) {
                setState((st) => ({
                    ...st,
                    name: WorkflowDetails.workflowName,
                    nextWorkFlow: WorkflowDetails.nextWorkflowId,
                    IsDeleted: WorkflowDetails.isDeleted ? false : true,
                    orderIndex: WorkflowDetails.orderIndex,
                    timesIntervalDuration: WorkflowDetails.timesIntervalDuration,
                    timedInterval: WorkflowDetails.timedInterval,
                    timedIntervalTypeAfter: WorkflowDetails.timedIntervalTypeAfter == true ? 'true' : 'false',
                    timedWorkflowId: WorkflowDetails.timedWorkflowId,
                    displayJobPriorityButton: WorkflowDetails.displayJobPriorityButton == 'Y' ? true : false,
                    displayCustomerRatingButton: WorkflowDetails.displayCustomerRatingButton == 'Y' ? true : false,
                    displayWebFormType: WorkflowDetails?.displayWebFormType ? WorkflowDetails?.displayWebFormType.toString() : '1',
                    webFormID: WorkflowDetails.webFormID,
                    technicianAllowed: WorkflowDetails.technicianAllowed,
                    internalAllowed: WorkflowDetails.internalAllowed,
                    customerAllowed: WorkflowDetails.customerAllowed,
                    auditorAllowed: WorkflowDetails.auditorAllowed,
                }));
                setWorkflowType((st) => ({
                    IsEntryPoint: WorkflowDetails.isEntryPoint,
                    IsTechnicianWorkflow: WorkflowDetails.isTechnicianWorkflow,
                    IsEndPoint: WorkflowDetails.isEndPoint,
                    Standard: WorkflowDetails.isEntryPoint || WorkflowDetails.isTechnicianWorkflow || WorkflowDetails.isEndPoint ? false : true
                }));
            }
        }
        // let result = await getWorkflows();
        // if (result.success) {
        //     setState((st) => ({ ...st, workFlowList: result.data }));
        // }

        // let res = await getProcesses();
        // if (res.success) {
        //     setState((st) => ({ ...st, processList: res.data }));
        // }
        let DropDownRes = await getWorkFlowList(workflowId || '');
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                webForm: DropDownRes?.data?.webForm,
                webFormList: DropDownRes?.data?.webFormType,
                workFlowList: DropDownRes?.data?.workflow,
                processList: DropDownRes?.data?.process
            }));
        }
    }, []);

    // Filter Workflow List according to Process id
    useEffect(() => {
        let filterWorkflowList = [];
        if (state.workflowProcessID) {
            filterWorkflowList = [...state.workFlowList].filter((n) => n.processID === state.workflowProcessID);
        } else {
            filterWorkflowList = [];
        }
        setState((st) => ({
            ...st,
            filterWorkflowList: filterWorkflowList
        }));
    }, [state.workflowProcessID, state.workFlowList]);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();
        if (state.name && state.orderIndex) {
            let res = '';
            if (workflowId) {
                res = await updateWorkflow(
                    workflowId,
                    state.name,
                    state.IsDeleted,
                    state.nextWorkFlow,
                    state.orderIndex,
                    workflowType.IsTechnicianWorkflow,
                    workflowType.IsEntryPoint,
                    workflowType.IsEndPoint,
                    state.timesIntervalDuration,
                    state.timedInterval,
                    state.timedIntervalTypeAfter,
                    state.timedWorkflowId,
                    state.displayJobPriorityButton,
                    state.displayCustomerRatingButton,
                    state.workflowProcessID,
                    state.displayWebFormType,
                    state.webFormID,
                    state.technicianAllowed,
                    state.internalAllowed,
                    state.customerAllowed,
                    state.auditorAllowed,
                );
            } else {
                res = await addWorkflow(
                    state.name,
                    state.nextWorkFlow,
                    state.orderIndex,
                    workflowType.IsTechnicianWorkflow,
                    workflowType.IsEntryPoint,
                    workflowType.IsEndPoint,
                    state.timesIntervalDuration,
                    state.timedInterval,
                    state.timedIntervalTypeAfter,
                    state.timedWorkflowId,
                    state.displayJobPriorityButton,
                    state.displayCustomerRatingButton,
                    state.workflowProcessID,
                    state.displayWebFormType,
                    state.webFormID,
                    state.technicianAllowed,
                    state.internalAllowed,
                    state.customerAllowed,
                    state.auditorAllowed,
                );
            }
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(true);
            }
            showToast(res.message);
        } else {
            showToast('Workflow Name and Sequence is required.');
        }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleWorkflowType = (name) => {
        setWorkflowType((st) => {
            const nst = { ...st };
            Object.keys(nst).forEach((v) => {
                nst[v] = false;
            });
            nst[name] = true;
            return nst;
        });
    };

    const handleBtn = (val, name) => {
        setState((st) => {
            const nst = { ...st, [name]: val[name] };
            return nst;
        });
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{workflowId ? <DialogTitles>Update Workflow</DialogTitles> : <DialogTitles>Add Workflow</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents style={{ overflow: 'auto', height: '100vh' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={7}>
                        <FormLabel component="legend">Name</FormLabel>
                        <TextBox autoFocus name="name" onChange={fieldChange} value={state.name} />
                    </Grid>
                    <Grid item xs={6} sm={5}>
                        <FormLabel component="legend">Sequence</FormLabel>
                        <TextBox type="number" name="orderIndex" value={state.orderIndex} onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormLabel component="legend" style={{ marginBottom: 14 }}>
                            Process
                        </FormLabel>

                        <SelectBox List={state.processList || []} name="workflowProcessID" value={state.workflowProcessID} onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormLabel component="legend" style={{ marginBottom: 14 }}>
                            Next Workflow
                        </FormLabel>
                        <SelectBox List={state.filterWorkflowList || []} name="nextWorkFlow" value={state.nextWorkFlow} onChange={fieldChange} />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography component="legend" color="secondary">
                            Display Webform
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <FormLabel component="legend" style={{ marginBottom: 14 }}>
                            Webform
                        </FormLabel>
                        <SelectBox List={state.webForm} name="webFormID" value={state.webFormID} onChange={fieldChange} />
                    </Grid>

                    <Grid item container spacing={1} xs={5} sm={4} md={6}>
                        <Grid item xs={12}>
                            Type
                        </Grid>
                        <Grid item xs={12}>
                            {state.webFormList && (
                                <AppButtonGroup
                                    name="displayWebFormType"
                                    value={state.displayWebFormType}
                                    options={state.webFormList}
                                    valuePropertyName="id"
                                    textPropertyName="name"
                                    onChange={(e) => handleBtn(e, 'displayWebFormType')}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography component="legend" color="secondary">
                            Timed Next Workflow
                        </Typography>
                    </Grid>
                    <Grid item container spacing={1} xs={5} sm={4} md={2}>
                        <Grid item xs={12}>
                            Trigger
                        </Grid>
                        <Grid item xs={12}>
                            {state.triggerList && (
                                <AppButtonGroup
                                    name="timedIntervalTypeAfter"
                                    value={state.timedIntervalTypeAfter}
                                    options={state.triggerList}
                                    valuePropertyName="triggerid"
                                    textPropertyName="triggerVal"
                                    onChange={(e) => handleBtn(e, 'timedIntervalTypeAfter')}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={7} sm={4} md={2}>
                        <FormLabel component="legend">Duration</FormLabel>
                        <TextBox
                            style={{ marginTop: 15 }}
                            type="number"
                            name="timesIntervalDuration"
                            value={state.timesIntervalDuration}
                            onChange={fieldChange}
                        />
                    </Grid>
                    <Grid item container spacing={1} xs={12} sm={4} md={3}>
                        <Grid item xs={12}>
                            Interval
                        </Grid>
                        <Grid item xs={12}>
                            {state.intervalList && (
                                <AppButtonGroup
                                    name="timedInterval"
                                    value={state.timedInterval}
                                    options={state.intervalList}
                                    valuePropertyName="intervalid"
                                    textPropertyName="intervalVal"
                                    onChange={(e) => handleBtn(e, 'timedInterval')}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <FormLabel component="legend" style={{ marginBottom: 14 }}>
                            Timed Next Workflow
                        </FormLabel>
                        {/* <SelectBox List={state.workFlowList || []} name="timedWorkflowId" value={state.timedWorkflowId} onChange={fieldChange} /> */}
                        <TextBox
                            style={{ marginTop: 15 }}
                            select
                            name="timedWorkflowId"
                            value={state.timedWorkflowId}
                            onChange={fieldChange}
                            variant="outlined"
                        >
                            {state.filterWorkflowList
                                .map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormLabel component="legend" className="btn_add">
                            Workflow Type
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={3}>
                                    <DefaultButton
                                        className={workflowType.IsTechnicianWorkflow ? 'btnActive' : 'btninActive'}
                                        value={workflowType.IsTechnicianWorkflow}
                                        name="IsTechnicianWorkflow"
                                        onClick={() => handleWorkflowType('IsTechnicianWorkflow')}
                                    >
                                        Technician
                                    </DefaultButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <DefaultButton
                                        className={workflowType.IsEntryPoint ? 'btnActive' : 'btninActive'}
                                        value={workflowType.IsEntryPoint}
                                        name="IsEntryPoint"
                                        onClick={() => handleWorkflowType('IsEntryPoint')}
                                    >
                                        First Workflow
                                    </DefaultButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <DefaultButton
                                        className={workflowType.IsEndPoint ? 'btnActive' : 'btninActive'}
                                        value={workflowType.IsEndPoint}
                                        name="IsEndPoint"
                                        onClick={() => handleWorkflowType('IsEndPoint')}
                                    >
                                        Last Workflow
                                    </DefaultButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <DefaultButton
                                        className={workflowType.Standard ? 'btnActive' : 'btninActive'}
                                        value={workflowType.Standard}
                                        name="Standard"
                                        onClick={() => handleWorkflowType('Standard')}
                                    >
                                        Standard
                                    </DefaultButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Display Job Priority Button?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.displayJobPriorityButton}
                                onNoClick={() => handleClick('displayJobPriorityButton', false)}
                                onYesClick={() => handleClick('displayJobPriorityButton', true)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Display Customer Rating Button?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.displayCustomerRatingButton}
                                onNoClick={() => handleClick('displayCustomerRatingButton', false)}
                                onYesClick={() => handleClick('displayCustomerRatingButton', true)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Technician Allowed?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.technicianAllowed}
                                onNoClick={() => handleClick('technicianAllowed', false)}
                                onYesClick={() => handleClick('technicianAllowed', true)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Internal Allowed?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.internalAllowed}
                                onNoClick={() => handleClick('internalAllowed', false)}
                                onYesClick={() => handleClick('internalAllowed', true)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Customer Allowed?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.customerAllowed}
                                onNoClick={() => handleClick('customerAllowed', false)}
                                onYesClick={() => handleClick('customerAllowed', true)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Auditor Allowed?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.auditorAllowed}
                                onNoClick={() => handleClick('auditorAllowed', false)}
                                onYesClick={() => handleClick('auditorAllowed', true)}
                            />
                        </Grid>
                    </Grid>

                    {workflowId && (
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid item xs={12}>
                                Is Active?
                            </Grid>
                            <Grid item xs={10}>
                                <YesNoButton
                                    state={state.IsDeleted}
                                    onNoClick={() => handleClick('IsDeleted', false)}
                                    onYesClick={() => handleClick('IsDeleted', true)}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {/* <Grid item xs={12} sm={12}>
                        {workflowId ? (
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={2} sm={2}>
                                        <FormLabel component="legend" className="btn_add">
                                            Is Active?
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <DefaultButton
                                            className={state.IsDeleted ? 'btnActive' : 'btninActive'}
                                            value={true}
                                            name="IsDeleted"
                                            onClick={() => handleClick('IsDeleted', true)}
                                        >
                                            Yes
                                        </DefaultButton>
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <DefaultButton
                                            className={state.IsDeleted ? 'btninActive' : 'btnActiveNo'}
                                            value={false}
                                            name="IsDeleted"
                                            onClick={() => handleClick('IsDeleted', false)}
                                        >
                                            No
                                        </DefaultButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid> */}
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddWorkflowModal;
