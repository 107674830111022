import { useContext } from 'react';
import AppContext from '../../../App/AppContext';

let Home = (props) => {
    const { portalSettings } = useContext(AppContext);
    const bn = !!portalSettings.portalSettingBanner
        ? portalSettings.portalSettingBanner
        : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingBannerImage}`;

    return (
        <div style={{ height: 'calc(100vh - 105px)', overflow: 'hidden' }}>
            <div style={{ height: '100%' }}>
                <img
                    src={bn}
                    height="100%"
                    width="100%"
                    style={{
                        objectFit: 'cover'
                    }}
                />
            </div>
        </div>
    );

    // <div>
    //     <h1>{portalSettings.portalSettingTitle}</h1>
    //     <img src={bn} />
    // </div>
};
export default Home;
