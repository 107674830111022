import { createContext, useCallback, useEffect, useState, useRef, useContext } from 'react';
import { getFailureCodes } from '../../../Core/Service/failureCode-service';
import { getWorkAccomplishCodes } from '../../../Core/Service/workaccomplished-service';
import { getTechWriteUp, AddTechWriteUp } from '../../../Core/Service/techWriteUp-service';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import AppContext from '../../../App/AppContext';
import formatters from '../../../Core/Grid/inputFormatter';
import { AppStorage } from '../../../Core/Service/storage-service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import _ from 'lodash';
export const TechWriteScreenContext = createContext();

export const TechWriteProvider = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, portalSettings, userRoles } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const [inputList, setInputList] = useState([{ technicianNotesItemFault: '', technicianNotesItemDiagnosis: '', technicianNotesItemCause: '' }]);
    const isTechnician = userRoles.includes('technician');

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const [masterData, setMasterData] = useState({
        failureCodes: [],
        workaccomplishcodes: []
    });
    const [selectedData, setSelectedData] = useState({
        selectedFailureCodes: [],
        selectedWorkAccomplishCodes: []
    });
    const signCanvas = useRef({});
    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: null,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };
    const [state, setState] = useState({
        showModal: false,
        fontResize: 16,
        technicians: [],
        readingtaken: '',
        failurereason: [],
        timeout: '',
        date: '',
        timein: '',
        mileageout: '',
        mileagein: '',
        workaccomplished: [],
        techniciansign: null,
        imgtechniciansign: null,
        smoketest: false,
        serviceindicator: false,
        radiocode: false,
        wipNumber: '',
        timeclock: false,
        registration: '',
        id: null,
        fields: {},
        errors: {},
        showGrid: false,
        GridID: null,
        odometerUnitsVM: [],
        technicianNotesReadingUnit: null,
        showCircularLoader: true,
        technicianNotesJobID: props.match.params?.technicianJobId,
        newJOBId: null,
        technicianName: '',
        technicianDateSigned: '',
        teamLeaderName: '',
        teamLeaderDateSigned: '',
        signedOff: false
    });

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        const list = [...inputList];
        let formIsValid = true;
        if (state.date === 'Invalid date') {
            formIsValid = false;
            errors['date'] = 'Date cannot be empty';
        }
        if (!state.readingtaken) {
            formIsValid = false;
            errors['readingtaken'] = 'Reading taken cannot be empty';
        }
        if (list[0]['technicianNotesItemCause'] === '') {
            formIsValid = false;
            errors['cause'] = 'Cause cannot be empty';
        }
        if (list[0]['technicianNotesItemFault'] === '') {
            formIsValid = false;
            errors['complaint'] = 'Complaint cannot be empty';
        }
        if (list[0]['technicianNotesItemDiagnosis'] === '') {
            formIsValid = false;
            errors['cure'] = 'Cure cannot be empty';
        }
        if (!state.techniciansign) {
            formIsValid = false;
            errors['techniciansign'] = 'Technician signature cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    useEffect(() => {
        const selectedFailure = masterData.failureCodes.filter((w) => state.failurereason.indexOf(w.id) > -1);
        const selectedWorkAccomplish = masterData.workaccomplishcodes.filter((w) => state.workaccomplished.indexOf(w.id) > -1);
        setSelectedData((st) => ({
            ...st,
            selectedFailureCodes: selectedFailure,
            selectedWorkAccomplishCodes: selectedWorkAccomplish
        }));
    }, [state.failurereason, state.workaccomplished, masterData.failureCodes, masterData.workaccomplishcodes]);
    const Id = props.match.params.jobId;
    const isTechReadonly = props.match.params?.isTechReadonly;

    const DisplayGridState = (i) => {
        setState((st) => ({
            ...st,
            showGrid: true,
            GridID: i
        }));
    };
    const TechBtnClick = (id) => {
        if (state.technicianNotesJobID != id) {
            setState((st) => ({
                ...st,
                technicianNotesJobID: id
            }));
        }
    };
    useEffect(async () => {
        let res1 = await getWorkAccomplishCodes();
        if (res1.success) {
            const WorkAccomplishlist = res1.data.map((w) => ({
                id: w.workAccomplishedCodeID,
                name: w.workAccomplishedDescription
            }));
            setMasterData((st) => ({
                ...st,
                workaccomplishcodes: WorkAccomplishlist
            }));
        }
        let res = await getFailureCodes();
        if (res.success) {
            const failureCode = res.data.map((w) => ({
                id: w.failureCode,
                name: w.failureDescription
            }));
            setMasterData((st) => ({ ...st, failureCodes: failureCode }));
        }
    }, []);

    const canAmendTechWriteUpFlag = AppStorage.getCanAmendTechWriteUp();

    useEffect(async () => {
        let result = await getTechWriteUp(Id, state.technicianNotesJobID);
        const updatedUser = result?.data;

        if (updatedUser) {
            setState((st) => ({
                ...st,
                technicianNotesJobID: updatedUser.technicianJobID,
                newJOBId: updatedUser.technicianJobID,
                status: updatedUser.status,
                id: updatedUser.technicianNotesHeaderRecordID,
                registration: updatedUser.registration,
                technicianNotesItemCause: updatedUser.technicianNotestechnicianNotesItemCause,
                workaccomplished: updatedUser.techWorkAccomplished.map((m) => m.techWorkAccomplishedCodeID),
                failurereason: updatedUser.techFailureCodes.map((m) => m.techFailureCodeID),
                technicianNotesItemDiagnosis: updatedUser.technicianNotesActionTaken,
                date: formatters.DateFormatter(updatedUser.technicianNotesDate),
                readingtaken: updatedUser.technicianNotesReadingTaken || '',
                technicianNotesItemFault: updatedUser.technicianNotesFault,
                mileagein: updatedUser.technicianNotesMileageIn || '',
                mileageout: updatedUser.technicianNotesMileageOut || '',
                radiocode: updatedUser.technicianNotesRadioCodeReset,
                serviceindicator: updatedUser.technicianNotesServiceIndicatorReset,
                smoketest: updatedUser.technicianNotesSmokeTestPerformed,
                imgtechniciansign: updatedUser.technicianNotesTechnicianSignature,
                techniciansign: updatedUser.technicianNotesTechnicianSignature,
                imgteamleadersign: updatedUser.technicianNotesTeamLeaderSignature,
                timeclock: updatedUser.technicianNotesTimeClockReset,
                timein: pad(updatedUser.technicianNotesTimeIn).toString().replace('.', ':'),
                timeout: pad(updatedUser.technicianNotesTimeOut).toString().replace('.', ':'),
                wipNumber: updatedUser.wipNumber,
                odometerUnitsVM: updatedUser?.odometerUnitsVM?.map((w) => ({
                    id: w.odometerUnitID,
                    name: w.odometerUnitDescription
                })),
                technicianNotesReadingUnit: updatedUser.technicianNotesReadingUnit,
                technicians: updatedUser.technicians,
                isTechReadonly:
                    !canAmendTechWriteUpFlag && (props.match.params?.technicianJobId == st.technicianNotesJobID && isTechReadonly == 'Y' ? false : true),
                showCircularLoader: false,
                technicianName: updatedUser.technicianNotesTechnicianName,
                technicianDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTechnicianDateSigned),
                teamLeaderName: updatedUser.technicianNotesTeamLeaderName,
                teamLeaderDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTeamLeaderDateSigned),
                customerName: updatedUser.customerName,
                signedOff: false // default false from techwrite up
            }));
            setInputList(
                !updatedUser.technicianNotesItem.length
                    ? [{ technicianNotesItemFault: '', technicianNotesItemDiagnosis: '', technicianNotesItemCause: '' }]
                    : updatedUser.technicianNotesItem
            );
        }
    }, [state.technicianNotesJobID]);

    function pad(n) {
        return n < 10 ? '0' + n : n;
    }
    const handleSubmit = async (val) => {
        let res = '';
        if (!portalSettings.portalSettingDisplaySubmitbutton) {
            if (handleValidation()) {
                res = await AddTechWriteUp(state, inputList, val);
            }
        } else {
            res = await AddTechWriteUp(state, inputList, val);
        }
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
        }
        showToast(res.message);
        if (res.success && fromStep) {
            setTimeout(() => {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }, 1000);
        }
    };

    const GetTechnicianSign = (o) => {
        setState((st) => {
            const nst = {
                ...st,
                ...o
            };
            return nst;
        });
    };

    const clearSig = (val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.teamleadersign = null;
            return newSt;
        });
    };

    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            return { ...st, [name]: value, fields: fields };
        });
    };
    const AutoWorkAccomplish = (event, value) => {
        setSelectedData((st) => ({
            ...st,
            selectedWorkAccomplishCodes: value
        }));
        setState((st) => {
            const nst = { ...st, workaccomplished: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoFrChange = (event, value) => {
        setSelectedData((st) => ({ ...st, selectedFailureCodes: value }));
        setState((st) => {
            const nst = { ...st, failurereason: value.map((c) => c.id) };
            return nst;
        });
    };

    const showConfirmationModal = () => {
        if (handleValidation('S')) {
            setState((st) => ({ ...st, showConfirmModal: true }));
        }
    };

    const hideConfirmationModal = () => {
        setState((st) => ({ ...st, showConfirmModal: false }));
    };

    const GlobalCss = withStyles({
        '@global': {
            body: {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`
            },
            '.MuiFormLabel-root, .MuiTypography-body1, .MuiButton-root': {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`
            },
            '.MuiOutlinedInput-inputMarginDense': {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`,
                paddingTop: '4.1px',
                paddingBottom: '4.1px'
            },
            '.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
                paddingTop: '4.1px',
                paddingBottom: '4.1px'
            }
        }
    })(() => null);

    const pullAndUpdateState = () => {
        setState((st) => ({
            ...st,
            showGrid: false
        }));
    };
    const getStandartdFromChild = (props) => {
        const list = [...inputList];
        list[state.GridID]['technicianNotesItemFault'] = props.complaint;
        list[state.GridID]['technicianNotesItemDiagnosis'] = props.cure;
        list[state.GridID]['technicianNotesItemCause'] = props.cause == null ? '' : props.cause;
        setInputList(list);
    };
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;

        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { technicianNotesItemFault: '', technicianNotesItemCause: '', technicianNotesItemDiagnosis: '' }]);
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signCanvas.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, techniciansign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };
    const zoomOutFun = () => {
        setState((st) => ({ ...st, fontResize: st.fontResize - 1 }));
    };
    const zoomInFun = (val) => {
        setState((st) => ({ ...st, fontResize: st.fontResize + 1 }));
    };
    const printDocument = () => {
        let input = document.getElementById('divToPrint');
        let calMaxHeight;
        html2canvas(input, {
            onclone: (cloned) => {
                let textArea = cloned.getElementsByTagName('textarea');
                cloned.getElementById('zommContainer').style.display = 'none';
                let getHeight = [];
                for (var i = 0; i < textArea.length; i++) {
                    getHeight.push(textArea[i].scrollHeight);
                }
                calMaxHeight = _.max(getHeight);
                for (var i = 0; i < textArea.length; i++) {
                    const div = document.createElement('div');
                    const textProp = textArea[i].getBoundingClientRect();
                    div.innerText = textArea[i].value;
                    div.style.padding = '2px -3px';
                    div.style.height = `${calMaxHeight}px`; //`${textProp.height}px`;
                    div.style.width = `${textProp.width}px`;
                    div.style.textAlign = 'left';
                    div.style.color = 'black';
                    div.style.fontSize = 8;
                    textArea[i].style.display = 'none';
                    textArea[i].parentElement.append(div);
                }
            }
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            // const pdf = new jsPDF({
            //     orientation: 'landscape'
            // });
            const pdf = new jsPDF('p', 'px', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();

            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight + calMaxHeight);
            // pdf.save('download.pdf');
            pdf.save(`TechnitianWriteUp-WIP${state.wipNumber}-${moment().format('DD-MMM-YYYY-HH:mm')}.pdf`);
        });
    };
    return (
        <TechWriteScreenContext.Provider
            value={{
                signCanvas,
                state,
                fromStep,
                masterData,
                inputList,
                selectedData,
                clear,
                DisplayGridState,
                GetTechnicianSign,
                handleSubmit,
                handleCheck,
                fieldChange,
                AutoWorkAccomplish,
                GlobalCss,
                AutoFrChange,
                handleAddClick,
                handleRemoveClick,
                handleInputChange,
                getStandartdFromChild,
                pullAndUpdateState,
                TechBtnClick,
                useDefaultSig,
                portalSettings,
                showConfirmationModal,
                hideConfirmationModal,
                zoomOutFun,
                zoomInFun,
                clearSig,
                printDocument,
                isTechnician
            }}
        >
            {props.children}
        </TechWriteScreenContext.Provider>
    );
};
