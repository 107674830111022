import React, { useContext, useEffect, useState } from 'react';
import {
    ButtonGroup,
    Grid,
    IconButton,
    LinearProgress,
    Tooltip,
    Badge,
    Card,
    CardHeader,
    CardContent,
    Typography,
    TextField,
    FormLabel,
    CircularProgress,
    Chip,
    Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { TextBox } from '../../../../Core/FormInput';
import moment from 'moment';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import formatters from '../../../../Core/Grid/inputFormatter';
import AppContext from '../../../../App/AppContext';
import { getReportingGetSafetyCheckDue, getReportingGetVehiceMOTDue, getReportingGetVehiceServices } from '../../../../Core/Service/ReportScreens-service';
import { VehicleModalDetail } from './vehicleDetailsModal';
import DialogComp from '../../../../Core/Modal/dialogModal';
import VehicleDueDetailsList from './VehicleDueDetailListModal';

const useStyles = makeStyles({
    yearInput: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '4.1px',
            paddingBottom: '4.1px',
            color: 'white'
        }
    },
    root: {
        minWidth: 275,
        height: '165px',
        border: ' 1px solid black'
        // height: '100%'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    cardContent: (props) => {
        let cardContentHeight = props.fromTab ? Math.max(120, (window.innerHeight - 170) / 4 - 60) : Math.max(120, (window.innerHeight - 80) / 4 - 90);
        return {
            height: cardContentHeight,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.3em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#1976d2',
                opacity: 5,
                outline: '1px solid slategrey'
            }
        };
    },
    cardRow: {
        padding: '3px',
        borderBottom: ' 1px dotted black',
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
            background: '#E8E8E8'
        }
    },
    cardHeader: {
        height: '10px',
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        border: (props) => `1px solid ${props.portalMenuBarBackgroundColour}`,
        color: 'white',
        textAlign: 'left',
        padding: '10px !important'
    },
    loaderContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        zIndex: 99,
        background: 'grey',
        opacity: '0.6'
    }
});

const years = () => {
    const years = [];
    const d = new Date();
    let startYear = d.getFullYear() - 10;
    let endYear = d.getFullYear() + 10;

    while (endYear - startYear >= 0) {
        years.push({ description: `${startYear}`, id: `${startYear}` });
        startYear = startYear + 1;
    }
    return years;
};

const monthsList = formatters.constantMonthList();

const VehicleDueCheckCalendar = (props) => {
    const { portalSettings, showTopNavAndMenu, loggedIn, isTopNav, userRoles } = useContext(AppContext);
    const classes = useStyles({ ...props, ...portalSettings });
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');

    const [state, setState] = useState({
        filterList: [],
        branchList: [],
        branchCode: {},
        monthCode: monthsList[`${new Date().getMonth()}`],
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        currentMonthDays: [],
        dueTypeList: [
            { description: 'All', id: 'all' },
            { description: 'MOT', id: 'mot' },
            { description: 'Service', id: 'service' },
            { description: 'Safety', id: 'safety' }
        ],
        dueType: { description: 'All', id: 'all' },
        filterData: []
    });

    useEffect(async () => {
        let res = [];
        let startDate = moment([state.yearCode.id, state.monthCode.id - 1])
            .startOf('month')
            .format('YYYY-MM-DD');
        let endDate = moment([state.yearCode.id, state.monthCode.id - 1])
            .endOf('month')
            .format('YYYY-MM-DD');

        setState((st) => ({
            ...st,
            showLoader: true
        }));
        if (state.dueType.id === 'mot') {
            res = await getReportingGetVehiceMOTDue(startDate, endDate);
            setState((st) => ({
                ...st,
                allData: [...res.data.list].map((p) => ({ ...p, dueType: 'MOT' }))
            }));
        } else if (state.dueType.id === 'service') {
            res = await getReportingGetVehiceServices(startDate, endDate);
            setState((st) => ({
                ...st,
                allData: [...res.data.list].map((p) => ({ ...p, dueType: 'Service' }))
            }));
        } else if (state.dueType.id === 'safety') {
            res = await getReportingGetSafetyCheckDue(startDate, endDate);

            setState((st) => ({
                ...st,
                allData: [...res.data.list].map((p) => ({ ...p, dueType: 'Safety' }))
            }));
        } else {
            let pros = [];
            pros.push(
                getReportingGetVehiceMOTDue(startDate, endDate),
                getReportingGetVehiceServices(startDate, endDate),
                getReportingGetSafetyCheckDue(startDate, endDate)
            );
            let responses = await Promise.all(pros);
            if (responses[0].success && responses[1].success && responses[2].success) {
                setState((st) => ({
                    ...st,
                    allData: [
                        ...responses[0].data.list.map((p) => ({ ...p, dueType: 'MOT' })),
                        ...responses[1].data.list.map((p) => ({ ...p, dueType: 'Service' })),
                        ...responses[2].data.list.map((p) => ({ ...p, dueType: 'Safety' }))
                    ]
                }));
            }
        }
    }, [state.yearCode, state.monthCode, state.dueType]);

    useEffect(() => {
        let currentMonthDays = Array.from(Array(moment(`${state.yearCode.id}-${state.monthCode.id}`, 'YYYY-MM').daysInMonth()), (_, i) => {
            return {
                dayNo: i + 1,
                DayDate: moment([state.yearCode.id, state.monthCode.id - 1, i + 1]).format('DD-MM-YYYY'),
                events: []
            };
        });

        if (state.allData) {
            let tempCurrentMonthDays = [...currentMonthDays];

            state.allData.forEach((p) => {
                let compareDate = '';
                if (p.dueType === 'MOT') {
                    compareDate = p.mot;
                } else if (p.dueType === 'Service') {
                    compareDate = p.nsd;
                } else if (p.dueType === 'Safety') {
                    compareDate = p.scd;
                }
                let index = tempCurrentMonthDays.findIndex((o) => o.DayDate == moment(compareDate).format('DD-MM-YYYY'));
                if (index > -1) {
                    tempCurrentMonthDays[index].events.push({ ...p });
                }
            });

            setState((st) => {
                let newSt = { ...st };
                newSt.currentMonthDays = currentMonthDays;
                newSt.filterData = [...tempCurrentMonthDays];
                newSt.showLoader = false;
                return newSt;
            });
        }
    }, [state.allData]);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    const showDetailListModalHandler = (list, date) => {
        setState((st) => {
            return { ...st, showDetailListModal: true, selectedVehicleDueList: list, selectedVehicleDueListDate: date };
        });
    };

    const closeDetailModalHandler = (val) => {
        setState((st) => {
            return { ...st, showDetailModal: false, showDetailListModal: false };
        });
    };

    const handleModal = () => {
        setState((st) => ({
            ...st,
            showModal: !st.showModal
        }));
    };

    const handleOpenModal = (selectedValues) => {
        setState((st) => ({
            ...st,
            selectedData: selectedValues || {},
            showModal: true
        }));
    };

    return (
        <div style={{ padding: '10px', position: 'relative' }}>
            <Grid container>
                <Grid container item spacing={2} style={{ marginBottom: '1px' }}>
                    <Grid item xs={3}>
                        <FormLabel>Select Month</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={monthsList}
                            name="Month"
                            value={state.monthCode}
                            onChange={handleAutoChange('monthCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormLabel>Select Year</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={years()}
                            name="Years"
                            value={state.yearCode}
                            onChange={handleAutoChange('yearCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                    {!isCustomer && (
                        <Grid item xs={3}>
                            <FormLabel>Due Check List</FormLabel>
                            <Autocomplete
                                disableClearable
                                autoSelect
                                options={state.dueTypeList}
                                name="Years"
                                value={state.dueType}
                                onChange={handleAutoChange('dueType')}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                            />
                        </Grid>
                    )}
                </Grid>
                {state.showLoader && (
                    <Grid item sm={12} container alignItems="center" justify="center" className={classes.loaderContainer}>
                        <CircularProgress />
                    </Grid>
                )}
                <Grid container item spacing={2}>
                    {state.filterData.map((o, i) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card className={classes.root}>
                                    <CardHeader
                                        action={
                                            <>
                                                {o.events.length !== 0 && (
                                                    <IconButton style={{ padding: '0px', color: 'white' }} disabled={o.events.length !== 0 ? false : true}>
                                                        <AspectRatioIcon
                                                            onClick={() =>
                                                                showDetailListModalHandler(
                                                                    o.events,
                                                                    `${i + 1} ${state.monthCode.description} ${state.yearCode.description}`
                                                                )
                                                            }
                                                        />
                                                    </IconButton>
                                                )}
                                            </>
                                        }
                                        titleTypographyProps={{ variant: 'body1' }}
                                        title={`${i + 1} ${state.monthCode.description} ${state.yearCode.description}`}
                                        className={classes.cardHeader}
                                    ></CardHeader>
                                    <CardContent style={{ padding: '0px 0px 0px 0px' }} className={classes.cardContent}>
                                        {o.events.length > 0 ? (
                                            o.events.map((k) => {
                                                return (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        alignItems="center"
                                                        className={classes.cardRow}
                                                        onClick={() => handleOpenModal(k)}
                                                    >
                                                        {/* <Grid item xs={1}>
                                                            ⓘ
                                                        </Grid> */}

                                                        <Grid container item xs={12} style={{ textAlign: 'left', fontSize: ' 12px' }}>
                                                            <Grid item xs={12}>
                                                                {/* <Typography variant="body2"> */}
                                                                {k.dueType} - {k.reg} - {k.dc}
                                                                {/* </Typography> */}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    height: '100%'
                                                }}
                                            >
                                                <div style={{ marginLeft: 'auto', marginRight: 'auto', color: '#D0D0D0', cursor: 'default' }}>No bookings</div>
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            {state.showModal ? <VehicleModalDetail onClose={handleModal} selectedData={state.selectedData} open={state.showModal} /> : null}
            {state.showDetailListModal ? (
                <DialogComp
                    title={`${state.dueType.description === 'All' ? 'MOT - Service - Safety - ' : state.dueType.description} Due on ${
                        state.selectedVehicleDueListDate
                    }`}
                    maxWidth="xl"
                    onClose={closeDetailModalHandler}
                    fullWidth
                >
                    <VehicleDueDetailsList selectedVehicleDueList={state.selectedVehicleDueList} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleDueCheckCalendar;
