import { Avatar, ButtonGroup } from '@material-ui/core';
import { Button, Collapse, FormLabel, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';

import { useState } from 'react';
// import { ProfitTable } from './profitlossTable';
import { useEffect } from 'react';
import moment from 'moment';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import { getReportsDropdowns, getProfitLossbyID } from '../../../../../Core/Service/reportService';
import { FilterListIcon } from '@material-ui/icons/FilterList';
import { BudgetSendBtn, FilterBtn, LinkedButton, SmallLinkedButton } from '../BudgetCore/budgetButton';
import { ProfitLossCards, UsedStocksCard, UsedStocksCardMobileView, UsedStocksCardTWO } from '../BudgetCore/budgetCardsUi_v2';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EventIcon from '@material-ui/icons/Event';
import { PandLLineChart } from '../BudgetCore/p&ldetailsChart';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

import formatters from '../../../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import CircularIndeterminate from '../BudgetCore/loader';

import { getUsedStockValuation } from '../../../../../Core/Service/ReportScreens-service';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';

let filterBtnList = [
    { label: 'COMBINED', id: 'C' },
    { label: 'RETAIL', id: 'R' },
    { label: 'TRADE', id: 'T' }
];

const crumbs = () => [
    { name: 'Home', path: '/' },
    { name: 'Used Stock', active: true },
    { name: 'Used Stock Valuation', active: true }
];

const UsedStockValuation = () => {
    const [state, setState] = useState({
        branches: [],
        tableRow: [],
        branchList: [],
        franchise: [],
        groupIds: [],
        franchiseIds: [],
        showTable: true,
        gpStock: {},
        mainData: [],
        selectedFilterBtn: filterBtnList[0].id,
        showLoader: true
    });

    const [WindowWidths, WindowHeights] = useWindowSize();

    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

    const history = useHistory();

    const [temp, tempOption] = useState();

    const [table, setTable] = useState(false);

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };
    useEffect(async () => {
        let DropDownRes = await getReportsDropdowns();
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                branchList: DropDownRes.data.branch,
                franchise: DropDownRes.data.franchiseCode
            }));
        }
    }, []);

    const handleTable = () => {
        setTable(!table);
    };

    useEffect(async () => {
        let combineData = [];
        let res = await getUsedStockValuation(state.selectedFilterBtn);
        if (res.success) {
            if (res?.data?.mainData != null && res?.data?.mainData.length > 0) {
                combineData = res?.data?.mainData.map((p) => {
                    let weeklyDataByIdList = res?.data?.weeklyData?.filter((q) => q.branchID == p.branchID);
                    p.weeklyDataList = [...(weeklyDataByIdList?.length > 0 ? weeklyDataByIdList : [])].sort(
                        (a, b) => moment(b.valuationDate1).format('YYYYMMDD') - moment(a.valuationDate1).format('YYYYMMDD')
                    );
                    p.weeklyDataList = p.weeklyDataList.map((m, index) => ({
                        ...m,
                        getCalculatedvalue: ((m.stockValue1 - p.weeklyDataList[index + 1]?.stockValue1) / p.weeklyDataList[index + 1]?.stockValue1) * 100
                    }));
                    return p;
                });
            }
        }

        setState((st) => ({
            ...st,
            gpStock: res?.data?.gpStock[0],
            gpValueData: res?.data?.gpValue,
            mainData: combineData || [],
            showLoader: false
        }));
    }, [state.startDate, state.endDate, state.franchiseIds, state.groupIds, state.selectedFilterBtn]);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleChangesfranch = (event, value) => {
        tempOption((st) => ({ ...st, franchiseVal: value }));
        setState((st) => {
            const nst = { ...st, franchiseIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const filterBtnChange = (id) => {
        setState((st) => ({
            ...st,
            selectedFilterBtn: id
        }));
    };

    const handleRedirect = (link) => {
        let searchQuery = `?isTrue=${true}`;
        history.push({
            pathname: link,
            search: searchQuery
        });
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
    };

    return (
        <div className="salesBudgetScreen" style={{ height: 'calc(100vh - 105px)', overflow: 'auto' }}>
            {/* <Grid container spacing={2}> */}
            {state.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <BreadCrumbs crumbs={crumbs()} />

                        {/* <Grid container item xs={12} sm={6} lg={5} xl={5}>
                            {filterBtnList.map((p) => {
                                return (
                                    <Grid item xs={4} className="containerPadding">
                                        <FilterBtn active={state.selectedFilterBtn == p.id} onClick={() => filterBtnChange(p.id)}>
                                            {p.label}
                                        </FilterBtn>
                                    </Grid>
                                );
                            })}
                        </Grid> */}

                        <Grid container justifyContent="space-evenly">
                            <Grid item xs={12} sm={12} lg={5} xl={5} className="containerPadding">
                                <Grid container alignItems="flex-start" justifyContent="space-between" className="USVbranchOverViewKip_card">
                                    <Grid item xs={10} xl={10}>
                                        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                                            <Grid item xs={12}>
                                                <div className="USVsecondaryHeading">GROUP STOCK</div>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%' }}>
                                                                    <div className="USVtype1_Text alignLeft">{state.gpStock.totalUnitStock}</div>
                                                                    <div className="USVtype3_Text alignLeft">Total Units in Stock</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%', marginBottom: 10 }}>
                                                                    <div className="USVtype1_Text alignLeft">
                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock.totalStockValue)}
                                                                    </div>
                                                                    <div className="USVtype3_Text alignLeft">Total Stock Value</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%', marginBottom: 10 }}>
                                                                    <div className="USVtype1_Text alignLeft">
                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock.avgSIV)}
                                                                    </div>
                                                                    <div className="USVtype3_Text alignLeft">Average SIV</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%', marginBottom: 10 }}>
                                                                    <div className="USVtype1_Text alignLeft">
                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock.previousYearAvg)}
                                                                    </div>
                                                                    <div className="USVtype3_Text alignLeft">Previous Year Average</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                            <Grid item xs={12}>
                                                <Grid container spacing={WindowWidths >= 600 ? 2 : 1}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%' }}>
                                                                    <div className="USVtype1_Text alignLeft">
                                                                        {formatters.ThousandSeparatorWithoutZero(state.gpStock.totalUnitStock)}
                                                                    </div>
                                                                    <div className="USVtype3_Text alignLeft">Total Units in Stock</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%', marginBottom: WindowWidths >= 600 ? 10 : 0 }}>
                                                                    <div className="USVtype1_Text alignLeft">
                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock.totalStockValue)}
                                                                    </div>
                                                                    <div className="USVtype3_Text alignLeft">Total Stock Value</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%', marginBottom: WindowWidths >= 600 ? 10 : 0 }}>
                                                                    <div className="USVtype1_Text alignLeft">
                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock.avgSIV)}
                                                                    </div>
                                                                    <div className="USVtype3_Text alignLeft">Average SIV</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                    <EventIcon className="AvatarFont" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ width: '100%', marginBottom: 10 }}>
                                                                    <div className="USVtype1_Text alignLeft">
                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock.previousYearAvg)}
                                                                    </div>
                                                                    <div className="USVtype3_Text alignLeft">Previous Year Average</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} xl={2}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <SmallLinkedButton onClick={() => handleRedirect('/usedStock')}>
                                                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 18 }} />
                                                        </SmallLinkedButton>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <SmallLinkedButton onClick={() => handleRedirect('/overagePriority')}>
                                                            OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 18 }} />
                                                        </SmallLinkedButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={7} xl={7} className="containerPadding">
                                <Grid
                                    container
                                    justifyContent="space-around"
                                    alignItems="center"
                                    className="USVbranchOverViewKip_card"
                                    style={{ padding: '8px 10px ' }}
                                >
                                    <div className="USVtype2_Text alignLeft p4 w100">GROUP STOCK VALUE (YR)</div>
                                    <ResponsiveContainer className="USV-Graph">
                                        <LineChart data={state.gpValueData}>
                                            <Tooltip />
                                            <CartesianGrid x={1} y={0} />
                                            <Line dataKey="stockValue" stroke="#1ECBE1" strokeWidth={4} Label />
                                            <XAxis
                                                dataKey="month"
                                                textAnchor="end"
                                                sclaeToFit="true"
                                                verticalAnchor="start"
                                                // interval={0}
                                                // angle="-60"
                                                style={{ fontSize: 10 }}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* {state.mainData.map((p) => {
                            return (
                                <>}
                                    
                                    <Grid item container xl={12} justifyContent="space-evenly" alignItems="center">
                                        <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                            <div
                                                className="USVUnitKipOne_card"
                                                style={{
                                                    background: '#569101',
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div>
                                                    <div className="mainHeading">{p.units}</div>
                                                    <div className="mainHeading">Units</div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                            <UsedStocksCard
                                                CurrentStockValue={p.stockValue}
                                                AverageStockValue={p.avgStockValue}
                                                PreviousYearAverage={p.prevYearAvgValue}
                                                stockLink={'/usedStock'}
                                                priorityLink={'/overagePriority'}
                                                history={history}
                                                branchID={p.branchID}
                                                branch={p.branch}
                                            />
                                        </Grid>
                                        <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                            <UsedStocksCardTWO list={p.weeklyDataList || []} />
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        })} */}
                        {state.mainData.map((p) => {
                            return (
                                <>
                                    {/* <div className="secondaryHeading p4 alignLeft">{p.branch}</div> */}
                                    <Grid item container xl={12} justifyContent="space-evenly" alignItems="center">
                                        {WindowWidths >= 600 && (
                                            <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                                <div
                                                    className="USVUnitKipOne_card"
                                                    style={{
                                                        background: '#569101',
                                                        color: 'white',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <div>
                                                        <div className="USVUnitHeading">{p.units}</div>
                                                        <div className="USVUnitHeading">Units</div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                        {WindowWidths >= 600 ? (
                                            <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                                <UsedStocksCard
                                                    CurrentStockValue={p.stockValue}
                                                    AverageStockValue={p.avgStockValue}
                                                    PreviousYearAverage={p.prevYearAvgValue}
                                                    stockLink={'/usedStock'}
                                                    priorityLink={'/overagePriority'}
                                                    history={history}
                                                    branchID={p.branchID}
                                                    branch={p.branch}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} className="containerPadding">
                                                <UsedStocksCardMobileView
                                                    CurrentStockValue={p.stockValue}
                                                    AverageStockValue={p.avgStockValue}
                                                    PreviousYearAverage={p.prevYearAvgValue}
                                                    stockLink={'/usedStock'}
                                                    priorityLink={'/overagePriority'}
                                                    history={history}
                                                    branchID={p.branchID}
                                                    branch={p.branchShortName}
                                                    Units={p.units}
                                                />
                                            </Grid>
                                        )}

                                        {WindowWidths >= 600 && (
                                            <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                                <UsedStocksCardTWO list={p.weeklyDataList || []} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default UsedStockValuation;
