import {get, post } from './http-calls';
export const addWebForm = async(id, name, url, buttonLabel, description, workflowID, navigateToDashboard) => {
    let res = await post(`WebForms`, {
        Id: id,
        Name: name,
        WebFormRelativeUrl: url,
        WebFormButtonLabel: buttonLabel,
        NonFunctionalDescription: description,
        NavigateToDashboardOnSubmit: navigateToDashboard,
        webformEditableWorkflowID: workflowID,
    }, { useAuthToken: true });
    return res;
}
export const DeleteWebForm = async(id) => {
    let res = await post(`WebForms/DeleteWebForms/${id}`, {
        Id: id,
    }, { useAuthToken: true });
    return res;
}