import { get } from './http-calls';

export const getWorkflowMovementHistory = async headerRecordId =>{
    let res = await get(`WorkflowMovement/${headerRecordId}`, { useAuthToken: true });    
    return res;
}

export const getReturnReasonHistory = async headerId =>{
    let res = await get(`ReturnReason_History/${headerId}`, { useAuthToken: true });    
    return res;
}