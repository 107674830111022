import moment from 'moment';
import { get, post, put } from './http-calls';

export const getTechWriteUp = async (data, technicianJobId) => {
    let res = '';
    if (technicianJobId) {
        res = await get(`/TechnicianWriteUpFromHeaderRecordID/${data}?TechnicianJobId=${technicianJobId}&time=${moment().format('YYMMDDHHmmSS')}`, {
            useAuthToken: true
        });
    } else {
        res = await get(`/TechnicianWriteUpFromHeaderRecordID/${data}?time=${moment().format('YYMMDDHHmmSS')}`, { useAuthToken: true });
    }
    return res;
};

export const AddTechWriteUp = async (data, NotesItem, val = 'S') => {
    let res = await post(
        '/SaveTechnicianWriteUp',
        {
            Mode: val,
            TechnicianNotesDate: data.date,
            TechnicianNotesHeaderRecordID: data.id,
            technicianNotesItem: NotesItem,
            TechnicianNotesMileageIn: data.mileagein,
            TechnicianNotesTimeIn: parseFloat(data.timein.replace(':', '.')),
            TechnicianNotesMileageOut: data.mileageout,
            TechnicianNotesTimeOut: parseFloat(data.timeout.replace(':', '.')),
            TechnicianNotesSmokeTestPerformed: data.smoketest,
            TechnicianNotesServiceIndicatorReset: data.serviceindicator,
            TechnicianNotesRadioCodeReset: data.radiocode,
            TechnicianNotesTimeClockReset: data.timeclock,
            TechnicianNotesTechnicianSignature: data.techniciansign,
            techWorkAccomplished: data.workaccomplished,
            TechnicianNotesReadingTaken: data.readingtaken,
            techFailureCodes: data.failurereason,
            TechnicianNotesTeamLeaderSignature: data.teamleadersign,
            TechnicianNotesReadingUnit: data.technicianNotesReadingUnit,
            TechnicianNotesJobID: data.newJOBId,
            SignedOff: data.signedOff || false
        },
        { useAuthToken: true }
    );
    return res;
};
export const getStandardWriteUp = async (IsAllRecord = 'N', langCode = 'en') => {
    let res = await get(`StandardWriteup?AllRecord=${IsAllRecord || 'N'}&LanguageCode=${langCode || 'en'}`, { useAuthToken: true });
    return res;
};

export const AddStandardWriteUp = async (data) => {
    let res = await post(`StandardWriteup`, data, { useAuthToken: true });
    return res;
};

export const AddStandardWriteUpUpdate = async (data) => {
    let res = await post(`StandardWriteup/add`, data, { useAuthToken: true });
    return res;
};

export const DeleteStandardWriteUp = async (id) => {
    let res = await post(
        `StandardWriteup/DeleteStandardWriteup/${id}`,
        {
            Id: id
        },
        { useAuthToken: true }
    );
    return res;
};

export const getStandardWriteUpById = async (id) => {
    let res = await get(`StandardWriteup/${id}`, { useAuthToken: true });
    return res;
};
