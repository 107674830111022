import React, { useContext, useMemo, useEffect, useState, useRef } from 'react';
import AppContext from '../../../App/AppContext';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import SignaturePad from 'react-signature-canvas';
import { TextBox, SecondaryButton, DialogContents, AppButtonGroup, PrimaryButton, CustomButton } from '../../../Core/FormInput';
import { Button, IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import '../../technician/technician-app/TechnicianStyle.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import formatters from '../../../Core/Grid/inputFormatter';
import { Backup } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { getBtteryTestDetails, postBtteryTestDetails } from '../../../Core/Service/batteryTest-service';
import { AppStorage } from '../../../Core/Service/storage-service';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputLabel } from '@material-ui/core';
import SecondaryBatteryModal from './SecondaryBatteryModal';
import getResizedImage from '../../../Core/CommonFunctions/imageResizer';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _ from 'lodash';
import moment from 'moment';

let styles = {
    required: {
        color: 'red',
        fontSize: 13,
        float: 'left'
    }
};

const BatteryTest = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, userRoles } = useContext(AppContext);
    const isAuditor = userRoles.includes('auditor');
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const signCanvas = useRef({});
    const signteamleaderCanvas = useRef({});
    const Id = props.match.params.jobId;

    const [state, setState] = useState({
        errors: {},
        showCircularLoader: true,
        fields: {},
        teamleadersign: null,
        odometerUnitsVM: [],
        batteryManufacturers: [],
        batteryTestResults: [],
        list: [{}, {}],
        yesNoOptions: [
            {
                id: true,
                name: 'Yes'
            },
            {
                id: false,
                name: 'No'
            }
        ],
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        isDefaultSig: false,
        open: false,
        primaryErrors: {},
        secondaryErrors: {},
        secBettry: false
    });

    useEffect(async () => {
        fromStep && hideTopNavAndMenu();
        let result = await getBtteryTestDetails(Id);
        const Data = result.data;
        if (result.success) {
            let batteryManufacturers = result.data.batteryManufacturers;
            let odometerUnits = result.data.odometerUnits;
            let batteryTest = result.data.batteryTest;
            let headerRecords = result.data.headerRecords[0];
            let batteryTestResults = result.data.batteryTestResults;
            setState((st) => {
                const nst = { ...st };
                if (batteryTest.length > 0) {
                    let bettryTestList = batteryTest.map((m) => ({
                        batteryTestBarcode: m.batteryTestBarcode || null,
                        batteryTestManufacturerID: m.batteryTestManufacturerID?.toString() || null,
                        batteryTestCapacity: m.batteryTestCapacity || null,
                        batteryTestId: m.batteryTestId,
                        batteryTestMBPartNumber: m.batteryTestMBPartNumber || null,
                        batteryTestColdTestCurrent: m.batteryTestColdTestCurrent || null,
                        batteryTestProductionWeek: m.batteryTestProductionWeek || null,
                        batteryTestProductionYear: m.batteryTestProductionYear || null,
                        batteryTestLeak: m.batteryTestLeak.toString(),
                        batteryTestLeakPosition: m.batteryTestLeakPosition || null,
                        batteryTestMidtronicsTestResult: m.batteryTestMidtronicsTestResult || null,
                        batteryTestTestCode: m.batteryTestTestCode || null,
                        batteryTestDamageCode: m.batteryTestDamageCode || null,
                        batteryTestPrintoutImage: m.batteryTestPrintoutImage || null,
                        batteryTestIsSecondBattery: m.batteryTestIsSecondBattery || false
                    }));
                    nst.list = [...bettryTestList];
                    nst.batterySignature = result.data.batteryTest[0]?.batteryTestSignature;
                    nst.batteryTestSignature = result.data.batteryTest[0]?.batteryTestSignature;
                    nst.isDefaultSig = result.data.batteryTest[0]?.batteryTestSignature ? true : false;
                    nst.batteryTestDate = formatters.DateFormatter(result.data.batteryTest[0]?.batteryTestDate);

                    nst.headerRecordID = result.data.batteryTest[0]?.headerRecordID;
                    nst.batteryTestOdometerReading = result.data.batteryTest[0]?.batteryTestOdometerReading;
                    nst.batteryTestOdometerUnit = result.data.batteryTest[0]?.batteryTestOdometerUnit;
                }
                nst.batteryManufacturers = batteryManufacturers;
                nst.batteryTestResults = batteryTestResults;
                nst.odometerUnitsVM = odometerUnits?.map((w) => ({
                    id: w.odometerUnitID,
                    name: w.odometerUnitDescription
                }));
                nst.headerRecordsInfo = headerRecords;
                nst.showCircularLoader = false;

                return nst;
            });
        }
    }, []);
    const fieldChange = (val) => (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };
            if (!val) {
                newSt[name] = value;
            }
            if (val === 1) {
                newSt.list[0][name] = value;
            }
            if (val === 2) {
                newSt.list[1][name] = value;
                newSt.secBettry = true;
            }

            return newSt;
        });
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, isDefaultSig: true, batteryTestSignature: AppStorage.getSignature() }));
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            batteryTestSignature: null,
            batterySignature: null
        }));
    };

    // const GetTechnicianSign = () => {
    //     setState((st) => {
    //         const nst = {
    //             ...st,
    //             batteryTestSignature: signCanvas.current.toDataURL()
    //         };
    //         return nst;
    //     });
    // };

    const handel = (param) => (val) => {
        let objKey = Object.keys(val);
        setState((st) => {
            let newSt = { ...st };
            if (param === 1) {
                newSt.list[0][objKey] = val[objKey];
            }
            if (param === 2) {
                newSt.list[1][objKey] = val[objKey];
            }
            return newSt;
        });
    };

    const GetTechnicianSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const clearLeaderSign = () => {
        if (state.isDefaultSig) {
            setState((st) => ({
                ...st,
                isDefaultSig: false,
                batteryTestSignature: ''
            }));
        } else {
            signCanvas?.current.clear();
            setState((st) => ({
                ...st,
                batteryTestSignature: null
            }));
        }
    };

    let handleImage = (param) => (event) => {
        if (event.target.files[0].type.includes('image')) {
            getResizedImage(event.target.files[0], (fileUrl) => {
                setState((st) => {
                    let newSt = { ...st };
                    if (param === 1) {
                        newSt.list[0].batteryTestPrintoutImage = fileUrl;
                    }
                    if (param === 2) {
                        newSt.list[1].batteryTestPrintoutImage = fileUrl;
                    }
                    return newSt;
                });
            });
        } else {
            showToast('Wrong file selected');
        }
    };

    const setVal = () => {
        setState((st) => ({ ...st, open: true }));
    };

    const clearImage = (val) => {
        setState((st) => {
            let newSt = { ...st };
            if (val === 1) {
                newSt.list[0].batteryTestPrintoutImage = null;
            }
            if (val === 2) {
                newSt.list[1].batteryTestPrintoutImage = null;
            }
            return newSt;
        });
    };

    const handleValidation = () => {
        let primary = state.list[0];
        let secondary = state.list[1];
        let primaryErrors = {};
        let secondaryErrors = {};
        let formIsValid = true;
        let errors = {};

        if (state.batteryTestDate === 'Invalid date' || !state.batteryTestDate) {
            formIsValid = false;
            errors.batteryTestDate = 'Date cannot be empty';
        }
        if (!state.batteryTestOdometerReading) {
            formIsValid = false;
            errors.batteryTestOdometerReading = 'Odometer Reading is required';
        }
        if (!state.batteryTestSignature) {
            formIsValid = false;
            errors.batteryTestSignature = 'Signature is required';
        }

        if (primary) {
            if (!primary.batteryTestManufacturerID) {
                formIsValid = false;
                primaryErrors.batteryTestManufacturerID = 'Please select battery manufacturer';
            }

            if (!primary.batteryTestProductionWeek) {
                formIsValid = false;
                primaryErrors.batteryTestProductionWeek = 'Week and Year is required';
            }
            if (!primary.batteryTestProductionYear) {
                formIsValid = false;
                primaryErrors.batteryTestProductionWeek = 'Week and Year is required';
            } else if (!/^(199\d|20[0-2]\d|2030)$/.test(primary.batteryTestProductionYear)) {
                formIsValid = false;
                primaryErrors.batteryTestProductionWeek = 'Please enter a valid year 1990-2030';
            }
            if (!primary.batteryTestLeak) {
                primaryErrors.batteryTestLeak = 'This field is required';
                formIsValid = false;
            } else if (primary.batteryTestLeak === 'true' && !primary.batteryTestLeakPosition) {
                primaryErrors.batteryTestLeakPosition = 'This field is required';
                formIsValid = false;
            }
            if (!primary.batteryTestMidtronicsTestResult) {
                primaryErrors.batteryTestMidtronicsTestResult = 'Please select Midtronics Test Result';
                formIsValid = false;
            }
            if (!primary.batteryTestPrintoutImage) {
                primaryErrors.batteryTestPrintoutImage = 'Please upload Receipt';
                formIsValid = false;
            }
        }
        if (state.secBettry) {
            if (secondary) {
                if (!secondary.batteryTestManufacturerID) {
                    formIsValid = false;
                    secondaryErrors.batteryTestManufacturerID = 'Please select battery manufacturer';
                }

                if (!secondary.batteryTestProductionWeek) {
                    formIsValid = false;
                    secondaryErrors.batteryTestProductionWeek = 'Week and Year is required';
                }
                if (!secondary.batteryTestProductionYear) {
                    formIsValid = false;
                    secondaryErrors.batteryTestProductionWeek = 'Week and Year is required';
                } else if (!/^(199\d|20[0-2]\d|2030)$/.test(secondary.batteryTestProductionYear)) {
                    formIsValid = false;
                    secondaryErrors.batteryTestProductionWeek = 'Please enter a valid year 1990-2030';
                }
                if (!secondary.batteryTestLeak) {
                    secondaryErrors.batteryTestLeak = 'This field is required';
                    formIsValid = false;
                } else if (secondary.batteryTestLeak === 'true' && !secondary.batteryTestLeakPosition) {
                    secondaryErrors.batteryTestLeakPosition = 'This field is required';
                    formIsValid = false;
                }
                if (!secondary.batteryTestMidtronicsTestResult) {
                    secondaryErrors.batteryTestMidtronicsTestResult = 'Please select Midtronics Test Result';
                    formIsValid = false;
                }
                if (!secondary.batteryTestPrintoutImage) {
                    secondaryErrors.batteryTestPrintoutImage = 'Please upload Receipt';
                    formIsValid = false;
                }
            }
        }

        setState((st) => ({
            ...st,
            errors: errors,
            primaryErrors: primaryErrors,
            secondaryErrors: secondaryErrors
        }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        let bettryTestList = state.list.map((m) => ({
            batteryTestBarcode: m.batteryTestBarcode || null,
            batteryTestManufacturerID: m.batteryTestManufacturerID || null,
            batteryTestCapacity: m.batteryTestCapacity || null,
            batteryTestMBPartNumber: m.batteryTestMBPartNumber || null,
            batteryTestColdTestCurrent: m.batteryTestColdTestCurrent || null,
            batteryTestProductionWeek: m.batteryTestProductionWeek || null,
            batteryTestProductionYear: m.batteryTestProductionYear || null,
            batteryTestLeak: m.batteryTestLeak,
            batteryTestLeakPosition: m.batteryTestLeakPosition || null,
            batteryTestMidtronicsTestResult: m.batteryTestMidtronicsTestResult || null,
            batteryTestTestCode: m.batteryTestTestCode || null,
            batteryTestDamageCode: m.batteryTestDamageCode || null,
            batteryTestPrintoutImage: m.batteryTestPrintoutImage || null,
            batteryTestId: m.batteryTestId || null,
            batteryTestIsSecondBattery: m.batteryTestIsSecondBattery || false
        }));

        if (handleValidation()) {
            let data = {
                headerRecordID: Id,
                batteryTestDate: state.batteryTestDate,
                batteryTestOdometerReading: state.batteryTestOdometerReading,
                batteryTestOdometerUnit: state.batteryTestOdometerUnit,
                batteryTestSignature: state.batteryTestSignature,
                batteryTestsListVM: bettryTestList
            };

            let res = await postBtteryTestDetails(data);
            if (res.success) {
                showToast(res.data.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            } else {
                console.error(res);
            }
        }
    };

    const printDocument = () => {
        let input = document.getElementById('divToPrint');
        let calMaxHeight;

        html2canvas(input, {
            onclone: (cloned) => {
                let textArea = cloned.getElementsByTagName('textarea');
                cloned.getElementById('zommContainer').style.display = 'none';
                let getHeight = [];
                for (var i = 0; i < textArea.length; i++) {
                    getHeight.push(textArea[i].scrollHeight);
                }
                calMaxHeight = _.max(getHeight);
                for (var i = 0; i < textArea.length; i++) {
                    const div = document.createElement('div');
                    const textProp = textArea[i].getBoundingClientRect();
                    div.innerText = textArea[i].value;
                    div.style.padding = '2px -3px';
                    div.style.height = `${calMaxHeight + textProp.height}px`;
                    div.style.width = `${textProp.width}px`;
                    div.style.textAlign = 'left';
                    div.style.color = 'black';
                    div.style.fontSize = 8;
                    textArea[i].style.display = 'none';
                    textArea[i].parentElement.append(div);
                }
            }
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'px', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`BatteryTest-${moment().format('DD-MMM-YYYY-HH:mm')}.pdf`);
        });
    };

    return (
        <>
            <div id="divToPrint">
                <DialogContents>
                    {state.showCircularLoader ? (
                        <Grid item sm={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid container spacing={1}>
                                Registration Number:&nbsp; <b> {state.headerRecordsInfo.registration}</b>
                                &nbsp;&nbsp;WIP No:&nbsp; <b> {state.headerRecordsInfo.wipNumber}</b>

                                <Grid item sm={3} style={{ position: 'absolute', top: '1px', right: '20px' }} id="zommContainer">
                                    <CustomButton
                                        color="secondary"
                                        icon={CloudDownloadIcon}
                                        onClick={printDocument}
                                        toolTipTitle="Download this document"
                                        iconFontSize={28}
                                    />
                                </Grid>

                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item container xs={12} md={8}>
                                    <Grid item container spacing={1}>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <FormLabel component="legend" className="mandatoryfields">
                                                Date
                                            </FormLabel>
                                            <TextBox
                                                id="date"
                                                type="date"
                                                name="batteryTestDate"
                                                value={state.batteryTestDate}
                                                onChange={fieldChange()}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                            <span style={styles.required}>{state.errors['batteryTestDate']}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <FormLabel component="legend" className="mandatoryfields">
                                                Odometer Reading
                                            </FormLabel>
                                            <TextBox
                                                type="number"
                                                required
                                                name="batteryTestOdometerReading"
                                                value={state.batteryTestOdometerReading}
                                                onChange={fieldChange()}
                                            />
                                            <span style={styles.required}>{state.errors['batteryTestOdometerReading']}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <FormLabel component="legend">Odometer Reading Unit</FormLabel>
                                            <TextBox
                                                select
                                                value={+state.batteryTestOdometerUnit}
                                                name="batteryTestOdometerUnit"
                                                onChange={fieldChange()}
                                                rows={2}
                                            >
                                                {state.odometerUnitsVM?.length > 0 &&
                                                    state.odometerUnitsVM.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </TextBox>
                                        </Grid>
                                        <Grid item container spacing={2} xs={12}>
                                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                                <Button
                                                    variant="contained"
                                                    color={!state.open ? 'primary' : ''}
                                                    className="setFullWidth"
                                                    onClick={() =>
                                                        setState((st) => ({
                                                            ...st,
                                                            open: false
                                                        }))
                                                    }
                                                >
                                                    Primary Battery
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                                <Button variant="contained" color={state.open ? 'primary' : ''} className="setFullWidth" onClick={setVal}>
                                                    Secondary Battery
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        {!state.open ? (
                                            <>
                                                <Grid item container xs={12} md={12} lg={5} xl={5} alignItems="center">
                                                    <Grid item xs={12} sm={4} md={3} lg={12} xl={10}>
                                                        <FormLabel className="mandatoryfields">Battery Manufacturer</FormLabel>
                                                    </Grid>
                                                    <Grid container item xs={12} sm={8} md={9} lg={12} xl={11}>
                                                        <AppButtonGroup
                                                            name="batteryTestManufacturerID"
                                                            value={state.list[0]?.batteryTestManufacturerID}
                                                            onChange={handel(1)}
                                                            options={state.batteryManufacturers}
                                                            // disabled={!step.isEditable}
                                                            valuePropertyName="id"
                                                            textPropertyName="name"
                                                        />
                                                        <span style={styles.required}>{state.primaryErrors.batteryTestManufacturerID}</span>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                                    <FormLabel component="legend">Barcode (Mode Designation)</FormLabel>
                                                    <TextBox
                                                        type="text"
                                                        required
                                                        name="batteryTestBarcode"
                                                        value={state.list[0]?.batteryTestBarcode}
                                                        onChange={fieldChange(1)}
                                                    />
                                                    {/* <span style={styles.required}>{state.errors['batteryTestBarcode']}</span> */}
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                                    <FormLabel component="legend">Capacity</FormLabel>
                                                    <TextBox
                                                        type="number"
                                                        required
                                                        name="batteryTestCapacity"
                                                        value={state.list[0]?.batteryTestCapacity}
                                                        onChange={fieldChange(1)}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">Ah</InputAdornment>
                                                        }}
                                                    />
                                                    <span style={styles.required}>{state.errors['batteryTestCapacity']}</span>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                                    <FormLabel component="legend">Cold Test Current</FormLabel>
                                                    <TextBox
                                                        type="number"
                                                        required
                                                        name="batteryTestColdTestCurrent"
                                                        value={state.list[0]?.batteryTestColdTestCurrent}
                                                        onChange={fieldChange(1)}
                                                    />
                                                    <span style={styles.required}>{state.errors['batteryTestColdTestCurrent']}</span>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                                    <FormLabel component="legend">MB Part Number</FormLabel>
                                                    <TextBox
                                                        type="text"
                                                        required
                                                        name="batteryTestMBPartNumber"
                                                        value={state.list[0]?.batteryTestMBPartNumber}
                                                        onChange={fieldChange(1)}
                                                    />
                                                    <span style={styles.required}>{state.errors['batteryTestMBPartNumber']}</span>
                                                </Grid>
                                                <Grid container item xs={12} sm={6} md={6} lg={4} xl={4}>
                                                    <Grid item xs={12}>
                                                        <FormLabel component="legend" className="mandatoryfields">
                                                            Production Date (on negative terminal)
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container item xs={6} alignItems="center" justify="flex-start">
                                                        {/* <FormLabel component="legend" className="mandatoryfields">
                                                    Weeks 
                                                </FormLabel> */}

                                                        <TextBox
                                                            type="tel"
                                                            placeholder="Week"
                                                            required
                                                            name="batteryTestProductionWeek"
                                                            value={state.list[0]?.batteryTestProductionWeek}
                                                            onChange={fieldChange(1)}
                                                            inputProps={{ maxLength: 2 }}
                                                        />
                                                    </Grid>
                                                    <Grid container item xs={6} alignItems="center" style={{ paddingLeft: 10 }}>
                                                        <TextBox
                                                            type="tel"
                                                            placeholder="Year"
                                                            required
                                                            name="batteryTestProductionYear"
                                                            value={state.list[0]?.batteryTestProductionYear}
                                                            onChange={fieldChange(1)}
                                                            inputProps={{ maxLength: 4 }}
                                                        />
                                                    </Grid>
                                                    <span style={styles.required}>{state.primaryErrors.batteryTestProductionWeek}</span>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={6}>
                                                    <div style={{ padding: '0px 5px' }}>
                                                        <Typography style={{ float: 'left', marginBottom: '-5px' }} variant="h6">
                                                            Visual Inspection
                                                        </Typography>
                                                        <Grid container>
                                                            <Grid container item xs={12} sm={6} md={6} spacing={1} alignItems="center">
                                                                <Grid item>
                                                                    <FormLabel component="legend" className="mandatoryfields">
                                                                        External signs of leaks
                                                                    </FormLabel>
                                                                </Grid>
                                                                <Grid item>
                                                                    <AppButtonGroup
                                                                        name="batteryTestLeak"
                                                                        value={state.list[0]?.batteryTestLeak}
                                                                        onChange={handel(1)}
                                                                        options={state.yesNoOptions}
                                                                        valuePropertyName="id"
                                                                        textPropertyName="name"
                                                                    />
                                                                </Grid>
                                                                <span style={styles.required}>{state.primaryErrors.batteryTestLeak}</span>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <FormLabel
                                                                    component="legend"
                                                                    className={`${state.batteryTestLeak === 'true' ? 'mandatoryfields' : ''}`}
                                                                >
                                                                    If yes, where is the leak:
                                                                </FormLabel>
                                                                <TextBox
                                                                    type="text"
                                                                    required
                                                                    name="batteryTestLeakPosition"
                                                                    value={state.list[0]?.batteryTestLeakPosition}
                                                                    onChange={fieldChange(1)}
                                                                />
                                                                <span style={styles.required}>{state.primaryErrors.batteryTestLeakPosition}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={6}>
                                                    <div div style={{ padding: '0px 5px' }}>
                                                        <Typography style={{ float: 'left', marginBottom: '-5px' }} variant="h6">
                                                            Midronics Test
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid container item alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <FormLabel component="legend" className="mandatoryfields">
                                                                        Result
                                                                    </FormLabel>
                                                                </Grid>

                                                                <Grid item>
                                                                    <AppButtonGroup
                                                                        name="batteryTestMidtronicsTestResult"
                                                                        value={state.list[0]?.batteryTestMidtronicsTestResult}
                                                                        onChange={handel(1)}
                                                                        options={state.batteryTestResults}
                                                                        // disabled={!step.isEditable}
                                                                        valuePropertyName="batteryTestResultCode"
                                                                        textPropertyName="batteryTestResultName"
                                                                    />
                                                                </Grid>
                                                                <span style={styles.required}>{state.primaryErrors.batteryTestMidtronicsTestResult}</span>
                                                                {/* <Grid item>
                                                        <PrimaryButton
                                                            className="setFullWidth"
                                                            onClick={() =>
                                                                setState((st) => ({
                                                                    ...st,
                                                                    open: true
                                                                }))
                                                            }
                                                        >
                                                            Secondary Battery
                                                        </PrimaryButton>
                                                    </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <div style={{ padding: '0px 5px' }}>
                                                        {/* <Typography style={{ float: 'left', marginBottom: '10px' }} variant="h6">
                                                Midronics Test
                                            </Typography> */}
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormLabel component="legend">Test Code</FormLabel>
                                                                <TextBox
                                                                    type="text"
                                                                    required
                                                                    name="batteryTestTestCode"
                                                                    value={state.list[0]?.batteryTestTestCode}
                                                                    onChange={fieldChange(1)}
                                                                />
                                                                <span style={styles.required}>{state.errors['batteryTestTestCode']}</span>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormLabel component="legend">Damage Code</FormLabel>
                                                                <TextBox
                                                                    type="text"
                                                                    required
                                                                    name="batteryTestDamageCode"
                                                                    value={state.list[0]?.batteryTestDamageCode}
                                                                    onChange={fieldChange(1)}
                                                                />
                                                                <span style={styles.required}>{state.errors['batteryTestDamageCode']}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </>
                                        ) : (
                                            <SecondaryBatteryModal
                                                state={state}
                                                fieldChange={fieldChange}
                                                handel={handel}
                                                handleImage={handleImage}
                                                clearImage={clearImage}
                                                styles={styles}
                                            />
                                        )}

                                        {!isAuditor && <Grid item xs={12}>
                                            <CustomeSignPad
                                                onChange={GetTechnicianSign}
                                                content={'Signature'}
                                                sign={state.batterySignature}
                                                name="batteryTestSignature"
                                                clearSig={clearSig}
                                            />
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={4}>
                                    {!state.open ? (
                                        <Grid item xs={12}>
                                            <FormLabel component="legend" className="mandatoryfields">
                                                Receipt
                                            </FormLabel>

                                            <Grid item xs={12} className="gridcontainer" style={{ height: '95%', position: 'relative' }}>
                                                {state.list[0]?.batteryTestPrintoutImage && (
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            right: 0,
                                                            top: 0
                                                        }}
                                                    >
                                                        <Tooltip title="Remove Receipt">
                                                            <CloseIcon fontSize="small" onClick={() => clearImage(1)} />
                                                        </Tooltip>
                                                    </span>
                                                )}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        height: '580px'
                                                    }}
                                                >
                                                    {state.list[0]?.batteryTestPrintoutImage ? (
                                                        <img
                                                            src={
                                                                state.list[0]?.batteryTestPrintoutImage?.includes('base64')
                                                                    ? state.list[0]?.batteryTestPrintoutImage
                                                                    : 'data:image/png;base64,' + state.list[0]?.batteryTestPrintoutImage
                                                            }
                                                            alt={'Receipt'}
                                                            style={{
                                                                objectFit: 'contain',
                                                                objectPosition: '50% 50%',
                                                                width: '100%',
                                                                height: '100%'
                                                                // border: 'solid 1px #CCC'
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                height: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <div>
                                                                <Tooltip title="Upload Document">
                                                                    <label>
                                                                        <IconButton color="secondary" component="span" style={{ padding: '0px' }}>
                                                                            <Backup style={{ fontSize: '60px' }} />
                                                                            <input
                                                                                type="file"
                                                                                onChange={handleImage(1)}
                                                                                // className="upload-btn-input-file"
                                                                                style={{ display: 'none' }}
                                                                                accept="image/*"
                                                                            />
                                                                        </IconButton>
                                                                    </label>
                                                                </Tooltip>
                                                            </div>
                                                            <p>No Receipt Found</p>
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <FormLabel component="legend" className="mandatoryfields">
                                                Receipt
                                            </FormLabel>

                                            <Grid item xs={12} className="gridcontainer" style={{ height: '95%', position: 'relative' }}>
                                                {state.list[1]?.batteryTestPrintoutImage && (
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            right: 0,
                                                            top: 0
                                                        }}
                                                    >
                                                        <Tooltip title="Remove Receipt">
                                                            <CloseIcon fontSize="small" onClick={() => clearImage(2)} />
                                                        </Tooltip>
                                                    </span>
                                                )}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        height: '580px'
                                                    }}
                                                >
                                                    {state.list[1]?.batteryTestPrintoutImage ? (
                                                        <img
                                                            src={
                                                                state.list[1]?.batteryTestPrintoutImage?.includes('base64')
                                                                    ? state.list[1]?.batteryTestPrintoutImage
                                                                    : 'data:image/png;base64,' + state.list[1]?.batteryTestPrintoutImage
                                                            }
                                                            alt={'Receipt'}
                                                            style={{
                                                                objectFit: 'contain',
                                                                objectPosition: '50% 50%',
                                                                width: '100%',
                                                                height: '100%'
                                                                // border: 'solid 1px #CCC'
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                height: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <div>
                                                                <Tooltip title="Upload Document">
                                                                    <label>
                                                                        <IconButton color="secondary" component="span" style={{ padding: '0px' }}>
                                                                            <Backup style={{ fontSize: '60px' }} />
                                                                            <input
                                                                                type="file"
                                                                                onChange={handleImage(2)}
                                                                                style={{ display: 'none' }}
                                                                                accept="image/*"
                                                                            />
                                                                        </IconButton>
                                                                    </label>
                                                                </Tooltip>
                                                            </div>
                                                            <p>No Receipt Found</p>
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <span style={styles.required}>{state.primaryErrors.batteryTestPrintoutImage}</span>
                                    <Grid item xs={12}>
                                        <SecondaryButton className="setFullWidth" onClick={handleSubmit} style={{ marginBottom: '5px' }} disabled={isAuditor}>
                                            Submit
                                        </SecondaryButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContents>
            </div>
        </>
    );
};
export default BatteryTest;
