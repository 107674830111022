import { Checkbox, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import AppContext from '../../../../App/AppContext';
import { DialogContents, Dialogs, DialogsActions, DialogTitles, SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { addUpdateReturnReason } from '../../../../Core/Service/returnReason-service';
import { getWorkflows } from '../../../../Core/Service/workflow-service';
import '../../User Management/UserManagement.css';

const AddReturnReasonModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        Reason: '',
        uniqIdentifier: '',
        errors: {},
        fields: {},
        Id: null,
        isActive: true,
        nextWorkflow: null,
        workFlowList: [],
        backgroundColorCode: '',
        textColorCode: ''
    });
    const WorkflowID = props.WorkflowID;
    const ReturnReasonDetails = props.ReturnReasonDetails;

    useEffect(async () => {
        if (ReturnReasonDetails) {
            setState((st) => ({
                ...st,
                Id: ReturnReasonDetails.returnReasonID,
                Reason: ReturnReasonDetails.returnReasonDescription,
                uniqIdentifier: ReturnReasonDetails.uniqIdentifier,
                // isActive: ReturnReasonDetails.isDeleted ? false : true,
                isActive: ReturnReasonDetails.returnReasonActive,
                nextWorkflow: ReturnReasonDetails.returnReasonNextWorkflowID,
                backgroundColorCode: ReturnReasonDetails.returnReasonColourCode,
                textColorCode: ReturnReasonDetails.returnReasonTextColourCode
            }));
        }
        let result1 = await getWorkflows();
        setState((st) => ({
            ...st,
            workFlowList: result1.data
        }));
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };
    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            let res = await addUpdateReturnReason(
                state.Id,
                state.Reason,
                WorkflowID,
                state.nextWorkflow,
                state.isActive,
                state.uniqIdentifier,
                state.backgroundColorCode,
                state.textColorCode
            );
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(false);
            }
            showToast(res.message);
        }
    };
    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.Reason) {
            formIsValid = false;
            errors['Reason'] = 'Reason cannot be empty';
        }
        if (!state.uniqIdentifier) {
            formIsValid = false;
            errors['uniqIdentifier'] = 'Next Workflow cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const validateSpace = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st };
            if (value.includes(' ')) {
                nst.uniqIdentifier = value.replace(/\s/g, '');
            }
            nst[name] = value;
            return nst;
        });
    };
    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{state.Id ? <DialogTitles>Update Reason</DialogTitles> : <DialogTitles>Add Reason</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents style={{ overflow: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Reason
                        </FormLabel>
                        <TextBox autoFocus name="Reason" onChange={fieldChange} value={state.Reason} />
                        <span className="mandatoryfields">{state.errors['Reason']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Next Workflow</FormLabel>
                        <TextBox select name="nextWorkflow" value={state.nextWorkflow} onChange={fieldChange} variant="outlined">
                            {state.workFlowList.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.workflowName}
                                </MenuItem>
                            ))}
                        </TextBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Unique Identifier
                        </FormLabel>
                        <TextBox
                            name="uniqIdentifier"
                            value={state.uniqIdentifier}
                            required
                            onChange={validateSpace}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                                readOnly: state.Id
                            }}
                        />
                        <span className="mandatoryfields">{state.errors['uniqIdentifier']}</span>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{ paddingBottom: '25px' }}>
                            <FormLabel component="legend">Background Colour</FormLabel>
                        </div>
                        <div>
                            <SketchPicker
                                color={state.backgroundColorCode}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        backgroundColorCode: updatedColor.hex
                                    }))
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{ paddingBottom: '25px' }}>
                            <FormLabel component="legend">Text Colour</FormLabel>
                        </div>
                        <div>
                            <SketchPicker
                                color={state.textColorCode}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        textColorCode: updatedColor.hex
                                    }))
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormControlLabel control={<Checkbox checked={state.isActive} onChange={handleCheckbox} name="isActive" />} label="Active?" />
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddReturnReasonModal;
