import React, { useState, useEffect } from 'react';
import { getInvoicesEmailed } from '../../../../Core/Service/InvoiceScreen-service';
import { Typography } from '@material-ui/core';
import DataGridComp from '../../../../Core/Grid';
import { GridOverlay } from '@material-ui/data-grid';
import formatters from '../../../../Core/Grid/inputFormatter';

const InvoicesEmailed = (props) => {
    const [state, setState] = useState({
        rows: []
    });

    useEffect(() => {
        pullEmailsAndUpdateState();
    }, [props.id]);

    const pullEmailsAndUpdateState = async () => {
        let res = await getInvoicesEmailed(props?.id);

        if (res.success) {

            let rows = res?.data?.table
            rows.forEach((item, i) => {
                item.id = i + 1;
            });

            setState((st) => ({
                ...st,
                rows: rows
            }));
        }
    }

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Invoices Emailed</div>
            </GridOverlay>
        );
    }

    const column_new = [
        {
            field: 'invoicePDFID',
            headerName: 'id',
            hide: true
        },
        {
            field: 'dateSent',
            headerName: 'Date Sent',
            flex: 0.9,
            renderCell: (params) => formatters.DateFormatter(params)
        },
        {
            field: 'sentBy',
            headerName: 'Sent By',
            flex: 2
        },
        {
            field: 'emailAddress',
            headerName: 'Email Address',
            flex: 3
        }
    ];

    return (
        <div className="report-screen-container">
            {state?.rows?.length == 0 ? (
                <Typography style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
                    No Emails Sent
                </Typography>
            ) : (
                <DataGridComp rows={state.rows} columns={column_new} enableSearch={false} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
            )}
        </div>
    );
};

export default InvoicesEmailed;
