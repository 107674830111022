import { CircularProgress, Grid, TableContainer } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AppContext from '../../../../../App/AppContext';
import {
    addReportingBudget,
    addReportingVehicleBudget,
    getNewUsedVehicleReportingBudget,
    getReportingBudget
} from '../../../../../Core/Service/ReportScreens-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import moment from 'moment';

const useStyles = makeStyles({
    table: {
        minWidth: 1750,
        '& .MuiTableCell-root': {
            padding: '10px 5px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    },
    totalsContainerStyle: {
        fontWeight: 600
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const NewUsedReportingBudgetTable = (props) => {
    const [state, setState] = useState({
        isRecords: false,
        rows: [],
        isActive: false,
        category: '',
        columnTotal: {},
        rows: [],
        calculatedRows: [],
        isActive: false,
        totals: {},
        changeRecordIds: [],
        isChangeTrig: false
    });
    const [modelTypeList, setModelTypeList] = useState([]);
    const [showCircularLoader, setShowCircularLoader] = useState(true);
    const [calculatedExpenseList, setCalculatedExpenseList] = useState([]);
    const { showToast } = useContext(AppContext);
    const classes = useStyles();

    const pullExpenseBudgetGridData = async () => {
        setShowCircularLoader(true);
        let sortedModelList = props.modelList
            .filter((p) => {
                if (props.selectedTargetType.isElectic) {
                    return p.isElectric && p.id == props.selectedCategories.id;
                } else {
                    return p.id == props.selectedCategories.id;
                }
            })
            .map((q) => ({ ...q, data: [] }));

        let res = await getNewUsedVehicleReportingBudget(props.vehicleType, props.yearCode?.id, props.selectedTargetType.id, props.selectedCategories.id);
        let tempResData = res.data || [];
        if (sortedModelList.length > 0) {
            tempResData.forEach((p) => {
                let objIndex = sortedModelList.findIndex((o) => o.code.trim() == p.code);
                if (objIndex > -1) {
                    sortedModelList[objIndex].data.push({ ...p, typeID: props.selectedTargetType.id });
                } else {
                    sortedModelList.push({
                        code: p.code,
                        name: p.name,
                        data: [{ ...p }]
                    });
                }
            });

            sortedModelList.forEach((r) => {
                let list2 = MonthShortNameList.map((m) => {
                    let obj = r.data.find((p) => moment(p.td).format('DD-MMM-YYYY').includes(`${m}`));
                    if (obj) {
                        return obj;
                    } else {
                        return {
                            code: r.code,
                            fc: props.vehicleType,
                            id: null,
                            name: r.name,
                            typeID: props.selectedTargetType.id,
                            td: moment(`01/${m}/${props.yearCode?.id}`).format('DD-MMM-YYYY'),
                            vt: 0,
                            vu: 0
                        };
                    }
                });
                r.data = list2;
            });
            console.log(sortedModelList, 'sortedModelList');
            setState((st) => {
                return { ...st, isRecords: true, isChangeTrig: false };
            });
        } else {
            setState((st) => {
                return { ...st, isRecords: false };
            });
        }
        setModelTypeList(sortedModelList);
        setShowCircularLoader(false);
    };

    useEffect(() => {
        pullExpenseBudgetGridData();
    }, [props.yearCode?.id, props.selectedCategories?.id]);

    useEffect(() => {
        props.handleActionTriggerCheck(state.isChangeTrig);
    }, [state.isChangeTrig]);

    useEffect(() => {
        let tempArr = [...modelTypeList];
        tempArr.forEach((t) => {
            let vtTotal = 0;
            let vuTotal = 0;
            t.data.forEach((q) => {
                vtTotal += +q.vt;
                vuTotal += +q.vu;
            });
            t.allVtAmount = vtTotal;
            t.allVuAmount = vuTotal;
        });

        let colCalculation = {
            modelTypeJanVtTotal: 0,
            modelTypeJanVuTotal: 0,
            modelTypeFebVtTotal: 0,
            modelTypeFebVuTotal: 0,
            modelTypeMarVtTotal: 0,
            modelTypeMarVuTotal: 0,
            modelTypeAprVtTotal: 0,
            modelTypeAprVuTotal: 0,
            modelTypeMayVtTotal: 0,
            modelTypeMayVuTotal: 0,
            modelTypeJunVtTotal: 0,
            modelTypeJunVuTotal: 0,
            modelTypeJulVtTotal: 0,
            modelTypeJulVuTotal: 0,
            modelTypeAugVtTotal: 0,
            modelTypeAugVuTotal: 0,
            modelTypeSepVtTotal: 0,
            modelTypeSepVuTotal: 0,
            modelTypeOctVtTotal: 0,
            modelTypeOctVuTotal: 0,
            modelTypeNovVtTotal: 0,
            modelTypeNovVuTotal: 0,
            modelTypeDecVtTotal: 0,
            modelTypeDecVuTotal: 0,
            modelTypeVtTotalTotal: 0,
            modelTypeVuTotalTotal: 0
        };
        let vtTotals = 0;
        let vuTotals = 0;
        tempArr.forEach((w) => {
            vtTotals += w.allVtAmount;
            vuTotals += w.allVuAmount;
            w.data.forEach((q) => {
                MonthShortNameList.map((m) => {
                    if (moment(q.td).format('DD-MMM-YYYY').includes(`${m}`)) {
                        colCalculation[`modelType${m}VtTotal`] += q.vt;
                        colCalculation[`modelType${m}VuTotal`] += q.vu;
                    }
                });
            });
            colCalculation.modelTypeVtTotalTotal = vtTotals;
            colCalculation.modelTypeVuTotalTotal = vuTotals;
        });
        tempArr.push({ name: 'Total', data: colCalculation });

        setCalculatedExpenseList(tempArr);
    }, [modelTypeList]);

    const gridInputChangeHandler = (index, code) => (e) => {
        let tempList = [...modelTypeList];
        let objIndex = tempList.findIndex((x) => x.code == code);
        tempList[objIndex].data[index] = {
            ...tempList[objIndex].data[index],
            [e.target.name]: +e.target.value,
            isChanged: true
        };

        setModelTypeList(tempList);
        setState((st) => {
            return { ...st, isChangeTrig: true };
        });
    };

    const InputWrapper = (index, code, TargetUnits, TargetAmount) => {
        return (
            <div style={{ display: 'flex' }}>
                <input
                    title={`${TargetUnits}`}
                    type="number"
                    name={'vu'}
                    onChange={gridInputChangeHandler(index, code)}
                    value={TargetUnits}
                    className="pa-4"
                    style={{ textAlign: 'right', width: '50%' }}
                />
                <span style={{ margin: '3px' }}> /</span>
                <input
                    title={`${TargetAmount}`}
                    type="number"
                    name={'vt'}
                    onChange={gridInputChangeHandler(index, code)}
                    value={TargetAmount}
                    className="pa-4"
                    style={{ textAlign: 'right' }}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        let tempModelTypeList = [...modelTypeList];

        let data = [];
        tempModelTypeList.forEach((f) => {
            let tempData = f.data
                .filter((j) => j.isChanged)
                .map(({ expenseCategoryName, isChanged, ...q }) => ({
                    reportingVehicleTargetUnits: q.vu,
                    reportingVehicleTargetAmount: q.vt,
                    reportingVehicleTargetCode: q.code,
                    reportingVehicleTargetTypeID: +q.typeID,
                    reportingVehicleTargetDate: q.td,
                    reportingVehicleTargetID: q.id
                }));
            data = [...data, ...tempData];
        });

        let filterPostData = {
            TargetType: props.vehicleType,
            VehicleTargets: data
        };

        let res = await addReportingVehicleBudget(filterPostData);
        if (res.success) {
            showToast('Save Successfully');
            pullExpenseBudgetGridData();
        }
    };

    useEffect(() => {
        props.childFunc.current = handleSubmit;
    }, [modelTypeList]);

    return (
        <Grid container spacing={2} style={{ paddingTop: '10px' }} justifyContent="center">
            {showCircularLoader ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 320px)' }} className="input-Number-remove-spinners">
                        <Table className={classes.table} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={120}>Model</TableCell>
                                    {MonthShortNameList.map((p) => {
                                        return (
                                            <TableCell align="center">
                                                <div>{p}</div>
                                                <div>Unit&nbsp;/&nbsp;Amount</div>
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell width={100} align="right">
                                        <div> Total</div>
                                        <div>Unit&nbsp;/&nbsp;Amount</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!state.isRecords ? (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {calculatedExpenseList.map((t) => {
                                            return (
                                                <>
                                                    {!t.code ? (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" className={classes.totalsContainerStyle}>
                                                                {t.name}
                                                            </TableCell>
                                                            {MonthShortNameList.map((p) => {
                                                                return (
                                                                    <TableCell align="center" className={classes.totalsContainerStyle}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div style={{ width: '30%', textAlign: 'right' }}>
                                                                                {formatters.ThousandSeparatorWithoutZero(t.data[`modelType${p}VuTotal`])}
                                                                            </div>
                                                                            <div style={{}}>&nbsp;/&nbsp;</div>
                                                                            <div style={{}}>
                                                                                {formatters.CurrencyThousandSeparatorWithoutZero(
                                                                                    t.data[`modelType${p}VtTotal`]
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell align="right" className={classes.totalsContainerStyle}>
                                                                {formatters.ThousandSeparatorWithoutZero(t.data.modelTypeVuTotalTotal)}
                                                                &nbsp;/&nbsp;
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(t.data.modelTypeVtTotalTotal)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                {t.name}
                                                            </TableCell>

                                                            {t.data.map((p, i) => {
                                                                return <TableCell align="center">{InputWrapper(i, t.code, p.vu, p.vt)}</TableCell>;
                                                            })}

                                                            <TableCell align="right" className={classes.totalsContainerStyle}>
                                                                {formatters.ThousandSeparatorWithoutZero(t.allVuAmount)}&nbsp;/&nbsp;
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(t.allVtAmount)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default NewUsedReportingBudgetTable;
