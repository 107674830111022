import moment from 'moment';
import { get, post } from './http-calls';

export const getWorkflowData = async (headerRecordId) => {
    let res = await get(`workflowData/${headerRecordId}`, {
        useAuthToken: true
    });
    return res;
};

export const saveWorkflowData = async (headerRecordId, data, technicianJobId) => {
    let res = '';
    if (technicianJobId) {
        res = await post(`workflowData/${headerRecordId}?TechnicianJobId=${technicianJobId}`, data, {
            useAuthToken: true
        });
    } else {
        res = await post(`workflowData/${headerRecordId}`, data, {
            useAuthToken: true
        });
    }
    return res;
};

export const assignedTechAndList = async (headerRecordId) => {
    let res = await get(`workflowData/getTechnician/${headerRecordId}?time=${moment().format('YYMMDDHHmmSS')}`, {
        useAuthToken: true
    });
    return res;
};

export const assignedTechnicianToJob = async (jobId, technicianIds) => {
    const prom = [];
    technicianIds.forEach((m) => {
        let technicianId = m.id;
        let mode = m.mode;
        prom.push(
            post(
                `workflowData/assignTechnician`,
                {
                    jobId,
                    technicianId,
                    mode
                },
                {
                    useAuthToken: true
                }
            )
        );
    });
    return Promise.all(prom)
        .then((ress) => {
            const techToSave = ress.filter((c) => !c.success);
            console.log('response from all technician assignment post calls:', ress);
            if (techToSave.length > 0) {
                return {
                    success: true,
                    message: techToSave[0].message
                };
            }
            return {
                success: true,
                message: 'Technician assigned successfully.'
            };
        })
        .catch((ex) => {
            console.error(ex);
            return {
                success: false,
                message: 'Failed to assign selected technicians.  Please try again.'
            };
        });
};
