import { Avatar, Chip, IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

export const CustomButton = (props) => {
    const { toolTipTitle, iconFontSize, IconColor, ...rest } = props;
    const Icon = props.icon;
    return (
        <span className="custom-icon-btn" style={{ cursor: 'pointer' }}>
            <Tooltip title={toolTipTitle || ''} arrow>
                <span>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={props.onClick} {...rest} 
                    >
                        <Icon style={{ fontSize: iconFontSize || 23, color: IconColor || 'red' }} />
                    </IconButton>
                </span>
            </Tooltip>
        </span>
    );
};
