import { get, post, put } from './http-calls';

export const getByWorkflowIdAndHeaderId = async (workflowId, headerId) => {
    let res = await get(`ReturnReasons/ByWorkflowId/${workflowId}${headerId ? `/${headerId}` : ''}`, { useAuthToken: true });
    return res;
};

// public long JobId { get; set; }
// public int ReturnReasonId { get; set; }
// public string Message { get; set; }
// public bool? returnSelect { get; set; }
// public long? TechnicianJobID { get; set; }
export const processsReturnReasonForJob = async (jobId, returnReasonId, anyNotes, selectedTechnician,resetSignature) => {
    let res = await post(
        `ReturnReasons/ProcessForJob`,
        {
            JobId: jobId,
            ReturnReasonId: returnReasonId,
            message: anyNotes,
            TechnicianJobID: selectedTechnician,
            resetSignature:resetSignature
        },
        { useAuthToken: true }
    );
    return res;
};

export const addUpdateReturnReason = async (id, name, workflowID, nextWorkflow, isActive, UniqIdentifier, backgroundColorCode, textColorCode) => {
    let res = await post(
        `ReturnReasons`,
        {
            ReturnReasonID: id,
            ReturnReasonDescription: name,
            ReturnReasonWorkflowID: workflowID,
            ReturnReasonNextWorkflowID: nextWorkflow,
            ReturnReasonActive: isActive,
            UniqIdentifier: UniqIdentifier,
            returnReasonColourCode: backgroundColorCode,
            returnReasonTextColourCode: textColorCode,
        },
        { useAuthToken: true }
    );
    return res;
};
