import { Button, CircularProgress, DialogContent, Grid, Tooltip, FormLabel, IconButton, Box } from '@material-ui/core';
import { TextBox, SecondaryButton, CustomButton, AppButtonGroup, SecondaryCheckbox } from '../../../Core/FormInput';
import React, { useEffect, useContext } from 'react';
import AppContext from '../../../App/AppContext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { DefectProvider, DefectScreenContext } from './defectProvider';
import SignaturePad from 'react-signature-canvas';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import '../../internal/defect-wc/defect.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogComp from '../.././../Core/Modal/dialogModal';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: 8,
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: 6
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },
    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        minWidth: '1000px'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0,
        marginLeft: -4,
        marginBottom: 20
    },
    checkContainer: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0px',
            marginRight: '0px'
        }
    }
}));

const AddDefects = (props) => {
    const {
        useDefaultSig,
        TechBtnClick,
        signCanvas,
        fromStep,
        state,
        clear,
        GetTechnicianSign,
        handleSubmit,
        handleAddClick,
        handleRemoveClick,
        handleInputChange,
        inputList,
        handleBtn,
        isTechnician,
        handleCheck,
        showModal,
        closeDialog
    } = useContext(DefectScreenContext);

    const { hideModal, showToast, hideTopNavAndMenu } = useContext(AppContext);
    const isReadonly = state.imgtechniciansign ? true : false;
    const history = useHistory();

    const classes = useStyles();

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);
    return (
        <div style={{ width: '100%' }}>
            <DialogContent className="mt-11">
                <Grid container spacing={1}>
                    <Grid item xs={9} sm={9} style={{ fontSize: 21, textAlign: 'left' }}>
                        Reg. No:&nbsp; <b> {state.headerRecords.registration}</b>&nbsp; WIP No:&nbsp; <b> {state.headerRecords.wipNumber}</b>
                    </Grid>
                    <Grid xs={12}>&nbsp;</Grid>
                    {/* {state.technicians.map((m) => (
                        <div className="tech-btn-group">
                            <Button className={state.activeUser == m.userID ? 'active-btn' : 'all-btn'} onClick={() => TechBtnClick(m.userID)}>
                                {m.technicianName}
                            </Button>
                        </div>
                    ))} */}
                    {state.showCircularLoader ? (
                        <Grid item sm={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} md={12} lg={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" className={classes.paper}>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left" width="1%">
                                                    Sign
                                                </StyledTableCell>
                                                <StyledTableCell align="left" width="23%">
                                                    Defect
                                                </StyledTableCell>
                                                <StyledTableCell align="left" width="15.3%">
                                                    Serviceable
                                                </StyledTableCell>
                                                <StyledTableCell align="left" width="20%">
                                                    Action
                                                </StyledTableCell>
                                                <StyledTableCell align="left" width="16%">
                                                    Created By
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="left" width="4%"></StyledTableCell> */}
                                                <StyledTableCell align="left" width="5%"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {inputList.map((x, i) => {
                                                return (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="left" className={classes.checkContainer}>
                                                            <FormControlLabel
                                                                control={
                                                                    <SecondaryCheckbox
                                                                        disabled={x?.defectCreatedUserSignature}
                                                                        checked={x?.checkDefectCreatedUserSignature}
                                                                        onChange={(e) => handleCheck(e, i)}
                                                                        value={x?.checkDefectCreatedUserSignature}
                                                                        name="checkDefectCreatedUserSignature"
                                                                    />
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <TextBox
                                                                name="defectDescription"
                                                                value={x.defectDescription}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                disabled={!state.flag}
                                                                placeholder="Defect Description"
                                                            />
                                                            <span className="mandatoryfields">{state.errors['defectDescription']}</span>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {' '}
                                                            {state.defectReportedCategories && (
                                                                <AppButtonGroup
                                                                    name={'defectReportedCategoryID'}
                                                                    value={x.defectReportedCategoryID}
                                                                    onChange={(e) => handleBtn(e, i, 'defectReportedCategoryID')}
                                                                    options={state.defectReportedCategories}
                                                                    disabled={!state.flag}
                                                                    valuePropertyName="defectReportedCategoryID"
                                                                    textPropertyName="defectReportedCategoryDescription"
                                                                />
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {state.defectActionedCategories && (
                                                                <AppButtonGroup
                                                                    name="defectActionedCategoryID"
                                                                    value={x.defectActionedCategoryID}
                                                                    onChange={(e) => handleBtn(e, i, 'defectActionedCategoryID')}
                                                                    options={state.defectActionedCategories}
                                                                    valuePropertyName="defectActionedCategoryID"
                                                                    textPropertyName="defectActionedCategoryDescription"
                                                                />
                                                            )}
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell align="left"></StyledTableCell> */}
                                                        <StyledTableCell align="left">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    {x.createdUser}
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="View Signature" aria-label="add">
                                                                        <div
                                                                            onClick={() => {
                                                                                showModal(x.defectCreatedUserSignature);
                                                                            }}
                                                                            style={{ cursor: 'pointer', marginTop: 10 }}
                                                                        >
                                                                            <img
                                                                                src={x.defectCreatedUserSignature}
                                                                                width="70px"
                                                                                style={{ display: 'table-cell', verticalAlign: 'middle' }}
                                                                            />
                                                                        </div>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {state.flag && isTechnician && (
                                                                <div>
                                                                    {inputList.length !== 1 && (
                                                                        <Tooltip title="Remove Defect" aria-label="add">
                                                                            <IconButton
                                                                                onClick={() => handleRemoveClick(i)}
                                                                                // className="rightButton"
                                                                                style={{
                                                                                    margin: '-16px 0px -17px 0px',
                                                                                    padding: 1
                                                                                }}
                                                                            >
                                                                                <RemoveCircleIcon color="primary" fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {inputList.length - 1 === i && (
                                                                        <Tooltip title="Add New Defect" aria-label="add">
                                                                            <IconButton
                                                                                onClick={handleAddClick}
                                                                                style={{
                                                                                    margin: '-16px 5px -17px 0px',
                                                                                    padding: 1
                                                                                }}
                                                                            >
                                                                                <AddBoxIcon color="secondary" fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Box width="100%" />

                            <Grid item xs={12} sm={12}>
                                <FormLabel
                                    component="legend"
                                    className="mandatoryfields"
                                    style={{
                                        marginBottom: '9px',
                                        marginTop: '5px'
                                    }}
                                >
                                    Technician Signature &nbsp;
                                    {(state.defectDateCreated || state.createdUser) && (
                                        <span> ( {`${state.createdUser || ' '} ${state.defectDateCreated}`} )</span>
                                    )}
                                    {!state.imgtechniciansign && (
                                        <CustomButton icon={BorderColorIcon} onClick={useDefaultSig} toolTipTitle="Use default signature" />
                                    )}
                                    &nbsp;
                                    {!state.imgtechniciansign && (
                                        <CustomButton icon={DeleteIcon} onClick={clear} IconColor="gray" toolTipTitle="Remove signature" />
                                    )}
                                </FormLabel>
                                {state.imgtechniciansign ? (
                                    <img
                                        src={state.imgtechniciansign}
                                        alt="my signature"
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                            border: '1px solid black',
                                            width: '100%',
                                            height: '100px'
                                        }}
                                    />
                                ) : (
                                    <>
                                        <SignaturePad
                                            ref={signCanvas}
                                            onEnd={() => {
                                                GetTechnicianSign();
                                            }}
                                            canvasProps={{
                                                className: 'signatureCanvas'
                                            }}
                                        />
                                        <span className="mandatoryfields">{state.errors.sign}</span>
                                    </>
                                )}
                                {/* <span className="mandatoryfields">{state.errors['techniciansign']}</span> */}
                            </Grid>
                            {state.flag && isTechnician && (
                                <>
                                    <Grid item xs={12} sm={4}>
                                        <SecondaryButton fullWidth onClick={() => handleSubmit('save')}>
                                            Save
                                        </SecondaryButton>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <SecondaryButton fullWidth onClick={() => handleSubmit('submit')}>
                                            Sign-Off
                                        </SecondaryButton>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <SecondaryButton fullWidth onClick={() => history.goBack()}>
                                            Cancel
                                        </SecondaryButton>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>
            {state.showSignature ? (
                <DialogComp title="Signature" maxWidth="sm" onClose={closeDialog}>
                    <img src={state.signature} alt="my signature" style={{ border: '1px solid', height: 178, width: '100%' }} />
                </DialogComp>
            ) : null}
        </div>
    );
};

const Screen = (props) => {
    return (
        <DefectProvider {...props}>
            <AddDefects {...props} />
        </DefectProvider>
    );
};
export default Screen;
