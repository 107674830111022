import { get } from './http-calls';
import { memoize } from 'lodash';

export const getsalesDashBoard = memoize(async () => {
    let res = await get(`Sales/SalesDashboard`, { useAuthToken: true });
    return res;
});

export const getUsedVehicle = memoize(async () => {
    let res = await get(`Sales/SaleUsedVehicleDashBoard`, { useAuthToken: true });
    return res;
});

export const getNewVehicle = memoize(async () => {
    let res = await get(`Sales/SaleNewVehicleDashBoard`, { useAuthToken: true });
    return res;
});

export const getVehicleContingency = memoize(async () => {
    let res = await get(`Sales/SaleContingencyDashBoard`, { useAuthToken: true });
    return res;
});
export const getOrderVehicle = memoize(async () => {
    let res = await get(`Sales/NewVehicleOrdersDashBoard`, { useAuthToken: true });
    return res;
});

export const getSalesVehicleDropdown = memoize(async () => {
    let res = await get(`Sales/SalesVehicleDropdown`, { useAuthToken: true });
    return res;
});
