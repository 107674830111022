import React, { useReducer } from 'react';
import { Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from '@material-ui/core';
import { reducer, initState } from '../../../Reducer/action';
import { SecondaryButton, TextBox, YesNoButton } from '../../../../Core/FormInput/index';
import { useState, useCallback } from 'react';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid/index';
import moment from 'moment';
import SelectBox from '../../../../Core/FormInput/SelectBox';
import DataGridComp from '../../../../Core/Grid';
import { GridOverlay } from '@material-ui/data-grid';
import { Email } from '@material-ui/icons';

const EmailModal = (props) => {
    const { onClose } = props;
    const [state, dispatch] = useReducer(reducer, initState);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ fontSize: 15, textAlign: 'left' }}>
                    WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Email
                    </InputLabel>
                    <TextBox
                        placeholder="Email"
                        // defaultValue="Default Value"
                        variant="outlined"
                        // multiline
                        // maxRows={4}
                        // name="jafar"
                        fullWidth
                        // onChange={(e) => {
                        //     dispatch({ type: 'HandleInput', payload: e.target });
                        // }}
                        // value={state.jafar}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        {' '}
                        Message
                    </InputLabel>
                    <TextBox
                        placeholder="Message"
                        id="outlined-multiline-static"
                        multiline
                        rows={20}
                        // defaultValue="Default Value"
                        variant="outlined"
                        // multiline
                        // maxRows={4}
                        // name="jafar"
                        // fullWidth
                        // onChange={(e) => {
                        //     dispatch({ type: 'HandleInput', payload: e.target });
                        // }}
                        // value={state.jafar}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SecondaryButton
                        className="Submitbtn"
                        fullWidth
                        onClick={() => {
                            alert('Work in Progress');
                        }}
                    >
                        Make Visible to Customer
                    </SecondaryButton>
                </Grid>

                <Grid item xs={4}>
                    <SecondaryButton
                        className="Submitbtn"
                        fullWidth
                        onClick={() => {
                            alert('Work in Progress');
                        }}
                    >
                        Make Visible And Email
                    </SecondaryButton>
                </Grid>
                <Grid item xs={4}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={() => onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default EmailModal;
