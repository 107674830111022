import { createContext, useCallback, useEffect, useState, useRef, useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { getDefectByID, defectPostdata } from '../../../Core/Service/defectTech-service';
import { find } from 'lodash';
import _ from 'lodash';
import { AppStorage } from '../../../Core/Service/storage-service';
import { arrayExpression } from '@babel/types';

export const DefectScreenContext = createContext();

export const DefectProvider = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, userRoles } = useContext(AppContext);
    const isTechnician = userRoles.includes('technician');
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const [inputList, setInputList] = useState([
        { defectDescription: '', defectReportedCategoryID: '', defectCreatedUserSignature: null, defectActionedCategoryID: '' }
    ]);

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const signCanvas = useRef({});
    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: null,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };
    const [state, setState] = useState({
        technicians: [],
        defectActionedCategories: [],
        techniciansign: null,
        imgtechniciansign: null,
        wipNumber: '',
        registration: '',
        id: null,
        errors: {},
        showCircularLoader: true,
        technicianNotesJobID: props.match.params?.technicianJobId,
        defectReportedCategories: [],
        defectHeaderRecordID: props.match.params.jobId || null,
        headerRecords: {},
        userID: null,
        flag: true,
        createdUser: null,
        defectDateCreated: null
    });

    const handleValidation = (val) => {
        let errors = {};
        const list = [...inputList];
        let formIsValid = true;
        if (val === 'submit') {
            if (list[0]['defectDescription'] === '') {
                formIsValid = false;
                errors['defectDescription'] = 'Description cannot be empty';
            }
            if (!state.techniciansign) {
                formIsValid = false;
                errors.sign = 'Technician Signature is required';
            }
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const Id = props.match.params.jobId;

    const handleBtn = (val, id, name) => {
        let changedArray = [...inputList];
        changedArray[id][name] = val[name];
        setInputList(changedArray);
    };

    const TechBtnClick = (userID) => {
        setState((newSt) => {
            let st = { ...newSt };
            if (userID === st.logedInuserID) {
                st.flag = true;
            } else st.flag = false;
            if (st.activeUser === userID) {
                st.showCircularLoader = false;
            } else {
                st.showCircularLoader = true;
            }
            st.activeUser = userID;
            st.userID = userID;
            return st;
        });
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signCanvas.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, techniciansign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };

    useEffect(async () => {
        if (Id) {
            let res = await getDefectByID(Id, state.userID);
            if (res.success) {
                const btnList = res.data.defectReportedCategories;
                if (res.data.defects) {
                    setInputList(
                        !res.data.defects.length
                            ? [{ defectDescription: '', defectReportedCategoryID: '', defectCreatedUserSignature: null, defectActionedCategoryID: '' }]
                            : res.data.defects
                    );
                }

                let header = res.data.headerRecords[0];
                const rw = find(res.data.technician, (t) => t.userID === header.loginUserId);
                let techArr = [...res.data.technician];
                if (isTechnician) {
                    if (!rw) {
                        techArr.unshift({ userID: header.loginUserId, technicianName: header.loginUserName });
                    }
                    if (header.loginUserId !== techArr[0].userID) {
                        techArr.map((m) => {
                            if (m.userID === techArr[0].userID) {
                                techArr.splice(0, 0, { userID: header.loginUserId, technicianName: header.loginUserName });
                            }
                        });
                    }
                }
                let CurrentUser = techArr[0]?.userID;
                var arrayOfObjAfter = _.uniqBy(techArr, 'userID');

                setState((st) => {
                    let newSt = { ...st };
                    if (!newSt.activeUser) {
                        newSt.activeUser = CurrentUser;
                        newSt.flag = true;
                    }
                    newSt.defectReportedCategories = btnList;
                    newSt.defectActionedCategories = res.data?.defectActionedCategories;
                    newSt.showCircularLoader = false;
                    // newSt.imgtechniciansign = res.data.defects[0]?.defectCreatedUserSignature || null;
                    // newSt.techniciansign = res.data.defects[0]?.defectCreatedUserSignature || null;
                    newSt.createdUser = res.data.defects[0]?.createdUser || null;
                    newSt.defectDateCreated = res.data.defects[0]?.defectDateCreated || null;
                    newSt.technicians = arrayOfObjAfter;
                    newSt.headerRecords = res.data.headerRecords[0];
                    newSt.logedInuserID = header.loginUserId;
                    return newSt;
                });
            }
        }
    }, [state.userID]);

    const handleSubmit = async (val) => {
        const defectVM = inputList.map((m) => ({
            userSignature: m.checkDefectCreatedUserSignature ? state.techniciansign : m.defectCreatedUserSignature,
            defectID: m.defectID || null,
            description: m.defectDescription,
            reportedCategoryID: m.defectReportedCategoryID,
            actionedCategoryID: m.defectActionedCategoryID
        }));
        if (handleValidation(val)) {
            let data = {
                defectHeaderRecordID: state.defectHeaderRecordID,
                mode: 'A',
                defectVM: defectVM
            };

            let res = await defectPostdata(data);
            if (res.success) {
                showToast(res.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            } else {
                showToast(res.error);
            }
        }
    };
    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        // if (name == 'defectDescription' || name == 'defectReportedCategoryID' || name == 'defectActionedCategoryID') {
        //     list[index]['defectCreatedUserSignature'] = null;
        //     list[index]['checkDefectCreatedUserSignature'] = true;
        // }
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { defectDescription: '', defectReportedCategoryID: '' }]);
    };

    const handleCheck = (event, index) => {
        const { name, checked } = event.target;
        const list = [...inputList];
        list[index][name] = checked;
        setInputList(list);
    };

    const showModal = (value) => {
        setState((st) => ({
            ...st,
            showSignature: true,
            signature: value
        }));
    };

    const closeDialog = () => {
        setState((st) => ({
            ...st,
            showSignature: false
        }));
    };
    return (
        <DefectScreenContext.Provider
            value={{
                signCanvas,
                state,
                fromStep,
                inputList,
                clear,
                GetTechnicianSign,
                handleSubmit,
                handleAddClick,
                handleRemoveClick,
                handleInputChange,
                TechBtnClick,
                handleBtn,
                hideModal,
                useDefaultSig,
                isTechnician,
                handleCheck,
                showModal,
                closeDialog
            }}
        >
            {props.children}
        </DefectScreenContext.Provider>
    );
};
