import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormControlLabel, Grid } from '@material-ui/core';
import { SecondaryCheckbox } from '../../../../Core/FormInput';
import { makeStyles } from '@material-ui/styles';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3
    },
    workflowCardContainer: {
        flex: 1
    }
}));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: grid,
    // width: 250,
    display: 'flex'
});

function spliceIntoChunks(arr, chunkSize) {
    const res = [];
    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize);
        res.push(chunk);
    }
    return res;
}

const chunkIntoN = (arr, n) => {
    const size = Math.ceil(arr.length / n);
    return Array.from({ length: n }, (v, i) => arr.slice(i * size, i * size + size));
};

export const DragNDropWrapperComponent = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();

    const classes = useStyles(props.isShowScrollArrow);
    const [state, setState] = useState({
        items: []
    });

    useEffect(() => {
        let sortedArray = [
            ...spliceIntoChunks([...props.CompList], window.innerWidth > 1280 ? 4 : window.innerWidth > 1080 ? 3 : window.innerWidth > 650 ? 2 : 1)
        ];
        console.log(sortedArray, 'sortedArray');
        setState((st) => ({
            ...st,
            items: sortedArray
        }));
    }, [props.CompList]);

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state.items[sInd], source.index, destination.index);
            const newState = { ...state };
            newState.items[sInd] = items;
            setState(newState);
            props.getGridSequenceList([...newState.items].flat(1));
        } else {
            const result = move(state.items[sInd], state.items[dInd], source, destination);
            const newState = [...state.items];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];
            let sortedArray = [
                ...spliceIntoChunks([...newState].flat(1), window.innerWidth > 1280 ? 4 : window.innerWidth > 1080 ? 3 : window.innerWidth > 650 ? 2 : 1)
            ];
            setState({ items: sortedArray.filter((group) => group.length) });
            props.getGridSequenceList([...newState].filter((group) => group.length).flat(1));
        }
    }

    return (
        <Grid container direction="column">
            <DragDropContext onDragEnd={onDragEnd}>
                {state.items.map((el, ind) => (
                    <Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                {el.map((item, index) => (
                                    <Draggable key={`${item.field}-${index}`} draggableId={`${item.field}-${index}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes.workflowCardContainer}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <SecondaryCheckbox
                                                            checked={props.fieldValues[item.fieldName]}
                                                            onChange={props.onChange}
                                                            name={item.fieldName}
                                                        />
                                                    }
                                                    label={item.title}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                {/* <div>opopo</div> */}
                            </div>
                        )}
                    </Droppable>
                ))}
            </DragDropContext>
        </Grid>
    );
};
