import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import './ReportingScreensStyle.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0,0,0,0.009)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#5c5c5c',
        color: 'white',
        padding: 9,
        border: 'none',
        fontWeight: 600
    },
    body: {
        fontSize: 14,
        border: 'none',
        padding: 9,
        borderBottom: '1px solid rgba(0,0,0,0.009)'
    }
}))(TableCell);

const useStyles = makeStyles({
    table: {
        overflow: 'auto',
        minWidth: '1400px',
        border: '1px solid rgba(0,0,0,0.002)'
    },
    surChargetable: {
        overflow: 'auto',
        minWidth: '100%',
        border: '1px solid rgba(0,0,0,0.002)'
    }
});
export const TechnicianBudgetTable = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const onRedirect = (params) => {
        let searchQuery = `?branchID=${params.branchId}&bn=${params.branch}&tabIndex=${params.index}`;
        history.push({
            pathname: '/ReportingBudget',
            search: searchQuery
        });
    };

    const { row, label } = props;
    return (
        <Grid container className="kpibox2" spacing={2}>
            <Grid item xs={12}>
                <Grid container alignContent="initial" justifyContent="space-between" alignItems="left">
                    <Grid item container xs={12} sm={12} spacing={1} className="cardComp">
                        <Grid item xs={12}>
                            <TableContainer style={{ minHeight: 260 }}>
                                <Table className={classes.surChargetable} stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <Typography
                                                    style={{
                                                        letterSpacing: '2px',
                                                        fontSize: 25,
                                                        backgroundColor: 'rgba(0,0,0,0.01)',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    {label}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="right"> Budgeted / Working</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    {props.list.length > 0 ? (
                                        <TableBody>
                                            {props.list.map((n, i) => {
                                                return (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell>
                                                            <b>{n.bn}</b>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <span
                                                                title="Budgeted"
                                                                style={{ color: 'black', cursor: 'pointer' }}
                                                                onClick={() => onRedirect({ branchId: n.branchID, branch: n.bn, index: 10 })}
                                                            >
                                                                {n.techniciansBudget ? formatters.ThousandSeparatorWithoutZero(n.techniciansBudget) : 'N/A'}
                                                            </span>{' '}
                                                            /{' '}
                                                            <span
                                                                title="Working"
                                                                onClick={() => onRedirect({ branchId: n.branchID, branch: n.bn, index: 11 })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: n.techniciansBudget > n.techniciansWorking ? 'red' : 'green'
                                                                }}
                                                            >
                                                                {n.techniciansWorking ? formatters.ThousandSeparatorWithoutZero(n.techniciansWorking) : 'N/A'}
                                                            </span>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={3} style={{ textAlign: 'center' }}>
                                                    No records found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
