import React, { useState } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { getReportsSalesDropdownsResolveData } from '../../../../../Core/Service/reportService';
import { SelectBox, SingelSelect, TextBox } from '../../../../../Core/FormInput';
import moment from 'moment';
import { useEffect } from 'react';
import { getSalesVehicleDropdown } from '../../../../../Core/Service/salesDasboard-service';

const baseColumns = [
    { title: 'SBN', field: 'sn', width: 80, sortable: true },
    {
        title: 'Vehicle Description',
        field: 'vd',
        width: 250,
        sortable: true,

        cssClass: 'text-center'
    },
    {
        title: 'Sold By',
        field: 'sp',
        width: 130,
        sortable: true
    },
    {
        title: 'Customer',
        field: 'cust',
        width: 130,
        sortable: true
    },

    {
        title: 'Order Date',
        field: 'od',
        width: 130,
        sortable: true,
        renderer: formatters.MonthShortFormatter
    },

    // {
    //     title: 'Comm Number',
    //     field: 'cn',
    //     width: 110,
    //     sortable: true
    // },

    {
        title: 'Branch',
        field: 'bn',
        width: 100,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'branchList',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Days',
        field: 'd',
        width: 90,
        sortable: true
    },
    {
        title: 'Notes',
        field: 'n',
        width: 150,
        sortable: true
    },
    {
        title: 'Latest Loc',
        field: 'rsc',
        width: 90,
        sortable: true
        // renderer: function (value, record) {
        //     return addCommas(value);
        // }
    },
    // {
    //     title: 'Vehicle Group',
    //     field: 'mac',
    //     width: 100,
    //     sortable: true,
    //     isDropDown: true,
    //     listIdFromAPIResponse: 'modelAnalysisCodes',
    //     dropDownValueField: 'name',
    //     dropDownLableField: 'name'
    // },
    // {
    //     title: 'Model',
    //     field: 'rmac',
    //     width: 90,
    //     sortable: true,
    //     isDropDown: true,
    //     listIdFromAPIResponse: 'modelRecords',
    //     dropDownValueField: 'name',
    //     dropDownLableField: 'name'
    // },
    {
        title: 'Order Type',
        field: 'ot',
        width: 90
    },
    { title: 'Status', field: 's', width: 100, sortable: true }
    // {
    //     title: 'Progress',
    //     field: 'pd',
    //     width: 80,
    //     sortable: true,
    //     isDropDown: true,
    //     listIdFromAPIResponse: 'progressCodes',
    //     dropDownValueField: 'id',
    //     dropDownLableField: 'name'
    // }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'New Vehicle Orders', active: true }
];

const detailTemplate = () => {
    return (
        "<div style='width:100%'><table style='width:100%;'>" +
        "<tr style='background-color:#DCDCDC;'>" +
        // "<th style='font-weight:750; text-align:left;'>Order Date</th >" +
        "<th style='font-weight:750; text-align:left;'>Progress</th >" +
        "<th style='font-weight:750; text-align:left;'>Comm Number</th >" +
        "<th style='font-weight:750; text-align:left;'>Build Date</th>" +
        "<th style='font-weight:750; text-align:left;'>Cons Date</th>" +
        // "<th style='font-weight:750; text-align:left;'>Est Date</th>" +
        // "<th style='font-weight:750; text-align:left;'>Est Profit</th></tr>" +
        '<tr><td>{pd}</td><td>{cn}</td><td>{bd}</td><td>{cd}</td></tr></div>'
    );
};

const VehicleNewOrder = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let PramMC = params.get('mc') || '';
    let PramCategoryType = params.get('CategoryType') || '';
    let startDateParams = params.get('startDate') || '';
    let endDateParams = params.get('endDate') || '';
    let typeID = params.get('type') || '';
    let modelID = params.get('model') || '';
    let isElectric = params.get('isElectric') || '';

    const [state, setState] = useState({
        categoriesList: [],
        // categoriesId: {},
        modelAnalysisCodeList: [],
        categoriesId: typeID ? (typeID === '1' ? { id: 1, name: 'VAN' } : { id: 2, name: 'TRUCK' }) : {},
        modelAnalysisCodeId: {},
        // modelAnalysisCodeId: {},
        EndDate: endDateParams || moment().format('YYYY-MM-DD'),
        StartDate: startDateParams || moment().startOf('month').format('YYYY-MM-DD')
    });

    useEffect(async () => {
        let res = await getSalesVehicleDropdown();
        console.log(res.data, 'sssssssss');
        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.categoriesList = res.data?.category || [];
                newSt.modelAnalysisCodeList = res.data?.modelAnalysisCode || [];
                if (PramCategoryType) {
                    newSt.categoriesId = res.data?.category.find((r) => r.id == PramCategoryType);
                }
                return newSt;
            });
        }
    }, []);
    useEffect(async () => {
        if (modelID) {
            setState((st) => {
                let newSt = { ...st };
                newSt.modelAnalysisCodeId = state.modelAnalysisCodeList.find((k) => k.id === modelID) || {};

                return newSt;
            });
        }
    }, [modelID, state.modelAnalysisCodeList]);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    useEffect(async () => {
        setState((st) => {
            let newSt = { ...st };
            if (JSON.stringify(state.categoriesId) !== '{}') {
                newSt.filterAnalysiscodelist = state.modelAnalysisCodeList.filter((k) => k.cid === state.categoriesId.id);
            } else {
                newSt.filterAnalysiscodelist = [...st.modelAnalysisCodeList];
            }
            return newSt;
        });
    }, [state.categoriesId, state.modelAnalysisCodeList]);

    const baseUrl = `Sales/NewVehicleOrders?StartDate=${state.StartDate}&EndDate=${state.EndDate}&VehicleCategoryID=${state.categoriesId?.id || ''}&rmac=${
        state.modelAnalysisCodeId?.id || ''
    }`;
    // &isElectric=${isElectric ? isElectric : false}`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={3}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <TextBox type="date" name="StartDate" value={state.StartDate} onChange={handleInput} />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <InputLabel shrink>End Date</InputLabel>
                    <TextBox type="date" name="EndDate" value={state.EndDate} onChange={handleInput} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <InputLabel shrink>Vehicle Categories</InputLabel>
                    <SingelSelect
                        options={state.categoriesList || []}
                        value={state.categoriesId}
                        // value={state.modelCode}
                        onChange={singleSelectChange('categoriesId')}
                        // disabled={state.isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <InputLabel shrink>Model</InputLabel>
                    <SingelSelect
                        options={state.filterAnalysiscodelist || []}
                        value={state.modelAnalysisCodeId}
                        onChange={singleSelectChange('modelAnalysisCodeId')}
                        // disabled={state.isReadOnly}
                    />
                </Grid>
            </Grid>

            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={baseColumns}
                getUrl={baseUrl}
                detailTemplate={detailTemplate}
                displayPagination={true}
                FilterdParamsValues={{ mc: PramMC }}
                downloadName={'New Vehicle Orders'}
                dropDownListProvider={getReportsSalesDropdownsResolveData}
            />
        </div>
    );
};

export default VehicleNewOrder;
