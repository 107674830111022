import React from 'react';
import { Grid, InputLabel, Checkbox } from '@material-ui/core';
import { SelectBox, TextBox } from '../../../../Core/FormInput';
import { SecondaryButton } from './../../../../Core/FormInput/AppButton';
import ViewPdfModal from '../../../../Core/Modal/PdfViweModal';
import { useEffect, useState } from 'react';
import { getInvoicesByID, invoiceScreenDropDown, getInvoicePdf, postUpdateInvoice } from '../../../../Core/Service/internal_Invoice-service';
import moment from 'moment';
import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';

const EditInvoice = (props) => {
    const { showToast, showModal } = useContext(AppContext);
    const [state, setState] = useState({
        invoiceStatusList: [],
        branchList: []
    });
    useEffect(async () => {
        if (props.invoiceID) {
            let res = await getInvoicesByID(props.invoiceID);

            let data = res?.data?.table[0];
            if (res.success) {
                setState((st) => ({
                    ...st,
                    invoiceId: data.invoiceId,
                    invoiceCreditNo001: data.invoiceCreditNo001,
                    wipNumber: data.wipNumber,
                    netValue: data.netValue,
                    grossValue: data.grossValue,
                    invoiceStatusID: data.invoiceStatusID,
                    customerName: data.customerName,
                    invoiceBranchID: data.invoiceBranchID,
                    postingDate: moment(data.postingDate).format('YYYY-MM-DD'),
                    invoiceFileName: data.invoiceFileName,
                    invoiceCustomerId: data.invoiceCustomerId,
                    oldInvoiceID: data.invoiceStatusID
                }));
            }
        }
    }, []);

    useEffect(async () => {
        if (state.invoiceFileName) {
            let res = await getInvoicePdf(state.invoiceFileName);

            if (res) {
                setState((st) => ({ ...st, pdfFileData: res }));
            } else {
                console.error(res.message);
            }
        }
    }, [state.invoiceFileName]);

    useEffect(async () => {
        let res = await invoiceScreenDropDown();

        if (res.success) {
            setState((st) => ({
                ...st,
                invoiceStatusList: res.data?.invoiceStatusList || [],
                branchList: res.data?.branchList || []
            }));
        }
    }, []);
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmit = async () => {
        let data = {
            grossValue: state.grossValue,
            invoiceBranchID: state.invoiceBranchID,
            invoiceCustomerId: state.invoiceCustomerId,
            id: state.invoiceId,
            invoiceNet: state.netValue,
            postDate: state.postingDate,
            invoiceWIPNumber: state.wipNumber,
            oldInvoiceStatusID: state.oldInvoiceID,
            invoiceStatusID: state.invoiceStatusID,
            invoiceNumber: props.invoiceNo
        };

        let res = await postUpdateInvoice(data);
        if (res.success) {
            showToast(`Invoice Updated Successfully`);
            props.onClose(true);
        } else {
            showToast(`Invoice Failed To Update`);
        }
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputLabel shrink>Invoice Number</InputLabel>
                            <TextBox type="text" onChange={handleFieldChange} name="invoiceCreditNo001" value={state.invoiceCreditNo001} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink>WIP Number</InputLabel>
                            <TextBox type="text" onChange={handleFieldChange} name="wipNumber" value={state.wipNumber} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink>Invoice Net</InputLabel>
                            <TextBox type="text" onChange={handleFieldChange} name="netValue" value={state.netValue} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink>Invoice Gross Value</InputLabel>
                            <TextBox type="text" onChange={handleFieldChange} name="grossValue" value={state.grossValue} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink>Post Date</InputLabel>
                            <TextBox type="date" onChange={handleFieldChange} name="postingDate" value={state.postingDate} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink style={{ paddingBottom: 10 }}>
                                Status
                            </InputLabel>
                            <SelectBox
                                onChange={handleFieldChange}
                                value={+state.invoiceStatusID}
                                name="invoiceStatusID"
                                List={state.invoiceStatusList || []}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink>Customer</InputLabel>
                            <TextBox type="text" onChange={handleFieldChange} name="customerName" value={state.customerName} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink style={{ paddingBottom: 10 }}>
                                Branch
                            </InputLabel>
                            <SelectBox onChange={handleFieldChange} value={+state.invoiceBranchID} name="invoiceBranchID" List={state.branchList} />
                        </Grid>
                        <Grid item xs={12}>
                            <SecondaryButton fullWidth onClick={handleSubmit}>
                                Update Invoice
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={8} spacing={1}>
                    <Grid item xs={12}>
                        <ViewPdfModal pdfFileData={state.pdfFileData} height={600} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <SecondaryButton fullWidth onClick={() => alert('Functionallity is pending')}>
                            Upload
                        </SecondaryButton>
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
    );
};

export default EditInvoice;
