import React from 'react';
import { Grid } from '@material-ui/core';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { getReportsSalesDropdownsResolveData } from '../../../../../Core/Service/reportService';

let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};

const baseColumns = [
    {
        title: 'Department',
        field: 'dd',
        width: 90,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'departmentCodes',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'WIP No.',
        field: 'wn',
        width: 100,
        sortable: true,

        cssClass: 'text-center'
    },
    {
        title: 'Raised',
        field: 'r',
        width: 100,
        sortable: true,
        // align: 'center',
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    { title: 'Account', field: 'ac', width: 100, sortable: true },
    {
        title: 'Phone No.',
        field: 'ph',
        width: 100,
        sortable: true
        // align: 'center',
        // renderer: function (value, record) {
        //     return formatters.MonthShortFormatter(value);
        // }
    },
    {
        title: 'Part Total',
        field: 'pt',
        width: 90,
        sortable: true,
        // align: 'right',
        renderer: function (value, record) {
            return addCommas(value);
        }
    },
    {
        title: 'Labour Total',
        field: 'lt',
        width: 90,
        sortable: true,
        align: 'right',
        renderer: function (value, record) {
            return addCommas(value);
        }
    },
    {
        title: 'Total',
        field: 't',
        width: 90,
        sortable: true,
        align: 'right',
        renderer: function (value, record) {
            return addCommas(value);
        }
    },

    {
        title: 'Address',
        field: 'addr',
        width: 200,
        sortable: true,
        align: 'center'
    },
    {
        title: 'Code',
        field: 'mdc',
        width: 90,
        sortable: true,
        align: 'right'
    },
    {
        title: 'Franchise',
        field: 'fc',
        width: 100,
        align: 'right',
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'franchiseRecords',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Branch',
        field: 'bn',
        width: 100,
        align: 'center',
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'branchList',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    { title: 'D', field: 'qv', width: 50, align: 'center', sortable: true }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Sales Contingency Debt', active: true }
];

const SalesContingencyDebt = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let PramFN = params.get('fn') || '';
    let PramBN = params.get('bn') || '';
    let PramDD = params.get('dd') || '';
    const baseUrl = `Sales/SalesContingencyDebt`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container>
                <Grid container>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={baseColumns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        FilterdParamsValues={{ fn: PramFN, bn: PramBN, dd: PramDD }}
                        downloadName={'Sales Contingency Debt'}
                        dropDownListProvider={getReportsSalesDropdownsResolveData}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SalesContingencyDebt;
