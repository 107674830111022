import { Avatar, ButtonGroup } from '@material-ui/core';
import { Button, Collapse, FormLabel, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { Multiselect, TextBox, DatePicker } from '../../../../../Core/FormInput';
import { useState } from 'react';
// import { ProfitTable } from './profitlossTable';
import { useEffect } from 'react';
import moment from 'moment';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import { getReportsDropdowns, getProfitLossbyID } from '../../../../../Core/Service/reportService';
import { FilterListIcon } from '@material-ui/icons/FilterList';
import { BudgetSendBtn, FilterBtn, LinkedButton, SmallLinkedButton } from '../BudgetCore/budgetButton';
import { ProfitLossCards, UsedStocksCard, UsedStocksCardMobileView, UsedStocksCardTWO } from '../BudgetCore/budgetCardsUi_v2';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EventIcon from '@material-ui/icons/Event';
import { PandLLineChart } from '../BudgetCore/p&ldetailsChart';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import UsedStocksTable from './usedStockTable';
import {
    getVehicleProfileListDropDown,
    postUsedVehicleStockGetExcelFile,
    postUsedVehicleStockValuation
} from '../../../../../Core/Service/ReportScreens-service';

import { useHistory } from 'react-router-dom';
import CircularIndeterminate from '../BudgetCore/loader';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { makeStyles } from '@material-ui/core/styles';

const crumbs = (ParamIsTrue) => [
    { name: 'Home', path: '/' },
    { name: 'Used Stock', active: true },
    ...(ParamIsTrue ? [{ name: 'Used Stock Valuation', path: '/UsedStockValuation' }] : []),
    { name: 'Used Vehicle Stock', active: true }
];
const useStyles = makeStyles((theme) => ({
    reportScreenMultiSelect: {
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: '65px',
            height: '50px',
            backgroundColor: '#fff',
            paddingRight: '25px !important'
        }
    }
}));

const UsedStock = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamIsTrue = params.get('isTrue') || false;
    const classes = useStyles();
    const [WindowWidths, WindowHeights] = useWindowSize();
    const [state, setState] = useState({
        branches: [],
        tableRow: [],
        branchList: [],
        VehicleProfile: [],
        groupIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.groupIds || [],
        VehicleProfileIds: [],
        showTable: true,
        showLoader: true
    });

    const [stockData, setStockData] = useState({
        branchData: [],
        tableData: [],
        weeklyData: [],
        gpValueData: [],
        mainData: [],
        showLoader: true
    });

    const history = useHistory();

    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

    const [temp, tempOption] = useState({
        groupVal: [],
        VehicleProfileVal: []
    });

    useEffect(async () => {
        let DropDownRes = await getReportsDropdowns();
        let newRes = await getVehicleProfileListDropDown();
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                branchList: DropDownRes?.data.branch || [],
                vehicleList: newRes?.data.list
            }));
        }
    }, []);

    console.log(WindowWidths, 'WindowWidths');

    useEffect(() => {
        if (state.branchList.length > 0) {
            if (state.groupIds.length > 0) {
                tempOption((st) => ({
                    ...st,
                    groupVal: state.groupIds.map((w) => {
                        let list = state.branchList.find((m) => w === m.id);
                        return list;
                    })
                }));
            }
        }
    }, [state.branchList]);

    useEffect(async () => {
        let data = {
            vehicleID: state.VehicleProfileIds,
            branchID: state.groupIds
        };
        let res = await postUsedVehicleStockValuation(data);
        if (res.success) {
            let combineData = [];
            if (res?.data?.mainData != null && res?.data?.mainData.length > 0) {
                combineData = res?.data?.mainData?.map((p) => {
                    let weeklyDataByIdList = res?.data?.weeklyData?.filter((q) => q.branchID == p.branchID);
                    p.weeklyDataList = [...(weeklyDataByIdList?.length > 0 ? weeklyDataByIdList : [])].sort(
                        (a, b) => moment(b.valuationDate1).format('YYYYMMDD') - moment(a.valuationDate1).format('YYYYMMDD')
                    );
                    p.weeklyDataList = p.weeklyDataList.map((m, index) => ({
                        ...m,
                        getCalculatedvalue: ((m.stockValue1 - p.weeklyDataList[index + 1]?.stockValue1) / p.weeklyDataList[index + 1]?.stockValue1) * 100
                    }));
                    return p;
                });
            }

            setStockData((st) => ({
                ...st,
                mainData: combineData,
                tableData: res?.data?.tableData || [],
                weeklyData: res?.data?.weeklyData,
                gpValueData: res?.data?.gpStock,
                showLoader: false
            }));
        }
    }, [state.VehicleProfileIds, state.groupIds]);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupVal: value, groupIds: value.map((c) => c.id) }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleChangesfranch = (event, value) => {
        tempOption((st) => ({ ...st, VehicleProfileVal: value }));
        // localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileVal: value, VehicleProfileIds: value.map((c) => c.id) }));

        setState((st) => {
            const nst = { ...st, VehicleProfileIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const downloadCSVWrapper = async () => {
        let data = {
            vehicleID: state.VehicleProfileIds,
            branchID: state.groupIds
        };
        await postUsedVehicleStockGetExcelFile(data, 'Used_Vehicle_Stock_Valuation');
    };

    return (
        <div className="salesBudgetScreen" style={{ height: 'calc(100vh - 105px)', overflow: 'auto' }}>
            {/* <Grid container spacing={2}> */}
            {stockData.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid item container xs={12} alignItems="center" alignContent="center" justifyContent="space-between">
                        <Grid item xs={10} sm={8} md={9} lg={10} xl={10}>
                            <BreadCrumbs crumbs={crumbs(ParamIsTrue)} />
                        </Grid>
                        <Grid item container xs={2} sm={4} md={3} lg={2} xl={2} justifyContent="flex-end">
                            <Grid item>
                                <LinkedButton onClick={downloadCSVWrapper}>
                                    <ViewComfyIcon />
                                    {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                                </LinkedButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={4} className="containerPadding">
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} lg={12}>
                                    <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                        SELECT BRANCH
                                    </FormLabel>
                                    <Multiselect
                                        className={classes.reportScreenMultiSelect}
                                        options={state.branchList || []}
                                        onChange={handleChanges}
                                        value={temp.groupVal}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={12}>
                                    <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                        VEHICLE PROFILE
                                    </FormLabel>
                                    <Multiselect
                                        className={classes.reportScreenMultiSelect}
                                        value={temp.VehicleProfileVal}
                                        options={state.vehicleList || []}
                                        onChange={handleChangesfranch}
                                    />
                                </Grid>
                            </Grid>
                            {/* </div> */}
                        </Grid>

                        <Grid item xs={12} sm={12} lg={7} xl={8} className="containerPadding">
                            <Grid
                                container
                                justifyContent="space-around"
                                alignItems="center"
                                className="branchOverViewKip_card"
                                style={{ padding: '0px 10px ' }}
                            >
                                <div className="type2_Text alignLeft p4 w100">GROUP STOCK VALUE (YR)</div>
                                <ResponsiveContainer className="USV-Graph">
                                    <LineChart data={stockData.gpValueData}>
                                        <Tooltip />
                                        <CartesianGrid x={1} y={0} />
                                        <Line dataKey="stockValue" stroke="#1ECBE1" strokeWidth={4} Label />
                                        <XAxis
                                            dataKey="month"
                                            textAnchor="end"
                                            sclaeToFit="true"
                                            verticalAnchor="start"
                                            // interval={0}
                                            // angle="-60"
                                            style={{ fontSize: 10 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* {stockData?.mainData?.map((p) => {
                        return (
                            <>
                                <Grid container>
                                    <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                        <div
                                            className="USVUnitKipOne_card"
                                            style={{ background: '#569101', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <div>
                                                <div className="mainHeading">{p.units}</div>
                                                <div className="mainHeading">Units</div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xl={3} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                        <UsedStocksCard
                                            CurrentStockValue={p?.stockValue}
                                            AverageStockValue={p?.avgStockValue}
                                            PreviousYearAverage={p?.prevYearAvgValue}
                                            active={true}
                                            stockLink={'/usedStock'}
                                            priorityLink={'/overagePriority'}
                                            history={history}
                                            branchID={p?.branchID}
                                            branch={p.branch}
                                        />
                                    </Grid>
                                    <Grid item xl={8} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                        <UsedStocksCardTWO list={p.weeklyDataList || []} />
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })} */}
                    {stockData?.mainData?.map((p) => {
                        return (
                            <>
                                <Grid container>
                                    {WindowWidths > 600 && (
                                        <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                            <div
                                                className="USVUnitKipOne_card"
                                                style={{
                                                    background: '#569101',
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div>
                                                    <div className="USVUnitHeading">{p.units}</div>
                                                    <div className="USVUnitHeading">Units</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    {WindowWidths >= 600 ? (
                                        <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                            <UsedStocksCard
                                                CurrentStockValue={p?.stockValue}
                                                AverageStockValue={p?.avgStockValue}
                                                PreviousYearAverage={p?.prevYearAvgValue}
                                                active={true}
                                                stockLink={'/usedStock'}
                                                priorityLink={'/overagePriority'}
                                                history={history}
                                                branchID={p?.branchID}
                                                branch={p.branch}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} className="containerPadding">
                                            <UsedStocksCardMobileView
                                                CurrentStockValue={p?.stockValue}
                                                AverageStockValue={p?.avgStockValue}
                                                PreviousYearAverage={p?.prevYearAvgValue}
                                                stockLink={'/usedStock'}
                                                priorityLink={'/overagePriority'}
                                                history={history}
                                                branchID={p?.branchID}
                                                branch={p.branchShortName}
                                                Units={p.units}
                                            />
                                        </Grid>
                                    )}

                                    {WindowWidths > 600 && (
                                        <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                            <UsedStocksCardTWO list={p.weeklyDataList || []} />
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        );
                    })}
                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={12} className="containerPadding">
                            <div className="kpiCard">
                                <UsedStocksTable list={stockData.tableData} />
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default UsedStock;
