import React, { useEffect, useState, useMemo, useContext } from 'react';
import moment from 'moment';
import { Grid, FormLabel, Tooltip, IconButton } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { TextBox } from '../../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import GetWIPHeader from '../../GetWIPDetails';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../../Core/Controls/Dialog/DialogComp';
import { getAgedMeasuresViewDocument } from '../../../../../Core/Service/ReportScreens-service';
import ViewPdfModal from '../../../../../Core/Modal/PdfViweModal';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { GetExcelFileFromEndpoint } from '../../../../../Core/Service/reportService';
import AppContext from '../../../../../App/AppContext';

const baseColumns = [
    { field: 'bn', title: 'Branch', width: 120, filterable: false, sortable: false },
    { field: 'sold', title: 'Sold Hours', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'taken', title: 'Taken Hours', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'eff', title: 'Efficiency %', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'sale', title: 'Sale Value', width: 120, filterable: false, sortable: false, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'cost', title: 'Cost Value', width: 120, filterable: false, sortable: false, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'profit', title: 'Profit', width: 120, filterable: false, sortable: false, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'marginPerc', title: 'Margin %', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'recRate', title: 'Rec Rate', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.CurrencyThousandSeparator }
];
var opColumns = [
    { title: 'Technician', field: 'tech', width: 90, sortable: false, align: 'left', tmpl: '{tech}' },
    { title: 'Sold', field: 'sold', width: 70, sortable: false, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Taken', field: 'taken', width: 70, sortable: false, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: '% Eff.', field: 'eff', width: 66, sortable: false, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Sale Value', field: 'sale', width: 80, sortable: false, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { title: 'Cost Value', field: 'cost', width: 80, sortable: false, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { title: 'Profit', field: 'profit', width: 80, sortable: false, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { title: 'Margin %', field: 'marginPerc', width: 60, sortable: false, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Rec Rate', field: 'recRate', width: 60, sortable: false, align: 'right', renderer: formatters.CurrencyThousandSeparator }
];
var wipColumns = [
    {
        title: 'WIP',
        field: 'wipNumber',
        width: 66,
        sortable: true,
        align: 'left'
    },
    {
        title: 'Invoice No',
        field: 'invoiceNumber',
        width: 70,
        sortable: true
    },
    { title: 'Customer', field: 'customersName', width: 130, sortable: true },
    { title: 'Sold', field: 'sold', width: 60, sortable: true, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Taken', field: 'taken', width: 60, sortable: true, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: '% Eff.', field: 'eff', width: 60, sortable: true, align: 'right', renderer: formatters.ToFixedSeparator },
    {
        title: 'Sale Value',
        field: 'sale',
        width: 70,
        sortable: true,
        renderer: formatters.CurrencyThousandSeparator,
        align: 'right'
    },
    {
        title: 'Cost Value',
        field: 'cost',
        width: 70,
        sortable: true,
        renderer: formatters.CurrencyThousandSeparator,
        align: 'right'
    },
    { title: 'Profit', field: 'profit', width: 60, sortable: true, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { title: 'Margin %', field: 'marginPerc', width: 60, sortable: true, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Rec Rate', field: 'recRate', width: 60, sortable: true, align: 'right', renderer: formatters.CurrencyThousandSeparator }
];
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Services', active: true },
    { name: 'Technician Efficiency', active: true }
];
var mainGrid, opGrid, wipsGrid;
var $ = window.$;

const TechnicianEfficiency = (props) => {
    const { showToast } = useContext(AppContext);

    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        showWIPDetails: false,
        wipNumber: '',
        company: ''
    });
    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showWIPDetails: true, wipNumber: record.wipNumber, company: record.company }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const documentHandler = async (record) => {
        let res = await getAgedMeasuresViewDocument(record.invoiceFileName);
        if (res) {
            setState((st) => ({ ...st, showPdfModal: true, pdfFileData: res }));
        } else {
            console.error(res.message);
        }
    };

    const docEditButton = (value, record) => {
        const spn = document.createElement('span');
        let isFile = record.invoiceFileName;
        const lnk = (
            <span style={{ cursor: isFile ? 'pointer' : 'text' }} onClick={isFile ? () => documentHandler(record) : ''}>
                {value}
            </span>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...wipColumns];
        let wipNumberCol = tempCols.find((element) => element.field === 'wipNumber');
        let ViewDocCol = tempCols.find((element) => element.field === 'invoiceNumber');
        if (ViewDocCol) {
            ViewDocCol.renderer = docEditButton;
        }
        if (wipNumberCol) {
            wipNumberCol.renderer = editButton({});
        }
        return tempCols;
    });
    let token = AppStorage.getToken();
    const onSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };

    const onSuccessOperatorFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        opGrid.render(obj);
    };
    const onSuccessWipFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        wipsGrid.render(obj);
    };

    const setDataSource = (gridInstance, getUrl) => {
        const onSuccessFunc = function (response) {
            gridInstance.render({
                records: response.data.list,
                total: response.data.total
            });
        };
        gridInstance.data().dataSource = {
            url: `${getUrl}`,
            data: { StartDate: state.startDate, EndDate: state.endDate },
            headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            success: onSuccessFunc
        };
    };

    const PullSalesNominalData = async () => {
        window.$(`#TechEfficiency`).grid('destroy', true, true);
        mainGrid = $('#TechEfficiency').grid({
            primaryKey: 'branchID',
            dataSource: [],
            // dataSource: {
            //     url: `${ExpBaseURL}/api/services/TechEfficiency`,
            //     data: { StartDate: state.startDate, EndDate: state.endDate },
            //     headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            //     success: onSuccessFunc
            // },
            detailTemplate: '<div><table/></div>',
            headerFilter: false,
            columns: baseColumns,
            pager: { limit: 10 }
        });

        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var recordsCount = records.length;
            var sumSold = 0,
                sumTaken = 0,
                sumEff = 0,
                sumSale = 0,
                sumCost = 0,
                sumProfit = 0,
                sumMarginPerc = 0,
                sumRecRate = 0,
                $tfoot;
            $.each(records, function () {
                sumSold += parseFloat(this.sold);
                sumTaken += parseFloat(this.taken);
                sumSale += parseFloat(this.sale);
                sumCost += parseFloat(this.cost);
                sumProfit += parseFloat(this.profit);
                sumMarginPerc += parseFloat(this.marginPerc);
                sumRecRate += parseFloat(this.recRate);
            });
            sumEff = parseFloat((sumSold / sumTaken) * 100);
            sumMarginPerc = parseFloat(((sumSale - sumCost) * 100) / sumSale);
            sumRecRate = parseFloat(sumSale / sumSold);
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th>Total</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumSold) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumTaken) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumEff) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumSale) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumCost) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumProfit) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumMarginPerc) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumRecRate) +
                    '</th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { branchID: branchID },
                // headerFilter: true,
                primaryKey: 'code',
                dataSource: [],
                // dataSource: {
                //     url: `${ExpBaseURL}/api/services/TechEfficiency`,
                //     headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                //     data: { StartDate: state.startDate, EndDate: state.endDate },
                //     success: onSuccessOperatorFunc
                // },
                columns: opColumns,
                pager: { limit: 10 },
                detailTemplate: '<div><table/></div>'
            });

            setDataSource(opGrid, `${ExpBaseURL}/api/services/TechEfficiency`);
            opGrid.reload();

            opGrid.on('detailExpand', function (e, $detailWrapper, code) {
                wipsGrid = $detailWrapper.find('table').grid({
                    params: { branchID: branchID, code: code },
                    primaryKey: 'id',
                    dataSource: [],
                    // dataSource: {
                    //     url: `${ExpBaseURL}/api/services/TechEfficiency`,
                    //     headers: {
                    //         Authorization: `Bearer ${token}`,
                    //         domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host
                    //     },
                    //     data: { StartDate: state.startDate, EndDate: state.endDate },
                    //     success: onSuccessWipFunc
                    // },
                    columns: columns,
                    pager: { limit: 10 }
                });
                setDataSource(wipsGrid, `${ExpBaseURL}/api/services/TechEfficiency`);
                wipsGrid.reload();
            });

            opGrid.on('detailCollapse', function (e, $detailWrapper, code) {
                $detailWrapper.find('table').grid('destroy', true, true);
            });
        });

        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        setDataSource(mainGrid, `${ExpBaseURL}/api/services/TechEfficiency`);
        mainGrid.reload();
    };
    const downloadCSVWrapper = async () => {
        showToast('Collating Data... This may take a while.');
        let downloadURL = `services/TechEfficiency?fileType=excel&StartDate=${state.startDate}&EndDate=${state.endDate}`;
        await GetExcelFileFromEndpoint(downloadURL, 'TechEfficiency');
    };
    useEffect(() => {
        PullSalesNominalData();
    }, [state.startDate, state.endDate, props.type]);
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <Grid item container spacing={1} xs={12} style={{ padding: '0px 10px' }}>
                {state.showWIPDetails && (
                    <DialogComp title="WIP Details" onClose={() => setState((st) => ({ ...st, showWIPDetails: false }))} maxWidth="xl" fullWidth>
                        <GetWIPHeader wipNumber={state.wipNumber} company={state.company} />
                    </DialogComp>
                )}
                {state.showPdfModal ? (
                    <DialogComp title="View PDF" maxWidth="md" onClose={() => setState((st) => ({ ...st, showPdfModal: false }))} fullWidth>
                        <ViewPdfModal pdfFileData={state.pdfFileData} />
                    </DialogComp>
                ) : null}
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'left' }}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'left' }}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                </Grid>
                <Grid container className="report-screen-gijgo " item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                    <Grid item style={{ bottom: '14px', left: '2%', zIndex: 99, position: 'absolute' }}>
                        <Tooltip title="Download Excel" arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: 'red' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <table id={`TechEfficiency`}></table>
                </Grid>
            </Grid>
        </div>
    );
};

export default TechnicianEfficiency;
